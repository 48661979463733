type ScrollToLettersProps = {
  letters: string[];
  scrollToSectionIdBeggining?: string;
};

export default function ScrollToLetters(props: ScrollToLettersProps) {
  return (
    <ul className="letter-filters">
      {props.letters.map((letter, index) => {
        return (
          <li
            key={index}
            className="filter-letter"
            onClick={() => {
              document
                .getElementById(
                  `${
                    props.scrollToSectionIdBeggining
                      ? props.scrollToSectionIdBeggining
                      : "letter-section"
                  }-${letter}`
                )
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {letter}
          </li>
        );
      })}
    </ul>
  );
}
