import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScreensaverSlide } from "../types/screensaverData";

type snackbarState = {
  message: string;
  visible: boolean;
};

type screensaverState = {
  slides: ScreensaverSlide[];
};

type sideMenuState = {
  expanded: boolean;
};

type layoutSliceState = {
  snackbar: snackbarState;
  screensaver: screensaverState;
  sideMenu: sideMenuState;
};

const initialState: layoutSliceState = {
  snackbar: {
    message: "",
    visible: false,
  },
  screensaver: {
    slides: [],
  },
  sideMenu: {
    expanded: false,
  }
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar = { visible: true, message: action.payload };
    },
    hideSnackbar: (state) => {
      state.snackbar = { ...state.snackbar, visible: false };
    },
    clearMessage: (state) => {
      state.snackbar = { ...state.snackbar, message: "" };
    },
    setScreensaverSlides: (state, action: PayloadAction<ScreensaverSlide[]>) => {
      state.screensaver = { slides: action.payload };
    },
    toggleSideMenuExpansion: (state) => {
      state.sideMenu.expanded = !state.sideMenu.expanded ;
    },
    closeSideMenu: (state) => {
      state.sideMenu.expanded = false;
    }
  },
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice.reducer;
