import { PropsWithChildren, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Button from "./Button";
import Icon from "./Icon";

type PopupProps = {
  onClose: () => void;
  isVisible: boolean;
  header?: string;
} & PropsWithChildren;

export default function Popup(props: PopupProps) {
  const { isVisible, header, children, onClose } = props;

  const backdropRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timer: number | undefined;

    const backdrop = backdropRef.current;
    const popup = popupRef.current;

    if (!backdrop || !popup) {
      return;
    }

    if (!isVisible) {
      backdrop.classList.remove("visible");
      popup.classList.remove("visible");
      timer = setTimeout(
        () => {
          popup.classList.remove("display");
          backdrop.classList.remove("display");
        },
        300,
        []
      );
    } else {
      backdrop.classList.add("display");
      popup.classList.add("display");
      timer = setTimeout(
        () => {
          backdrop.classList.add("visible");
          popup.classList.add("visible");
        },
        10,
        []
      );
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  return ReactDOM.createPortal(
    <>
      <div className="backdrop" ref={backdropRef} onClick={onClose}></div>
      <div className="popup" ref={popupRef}>
        <div className="popup-header">
          {header && <h4>{header}</h4>}
          <Button variant="secondary" onClick={onClose}>
            <Icon name={"close"}/>
          </Button>
        </div>
        <div className="popup-body">{children}</div>
      </div>
    </>,
    document.body
  );
}
