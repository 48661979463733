import { useTranslation } from "react-i18next";
import { Urls } from "../utils/constants/frontendConstants";
import { useCallback } from "react";

export default function useLocalizedLinks() {
  const activeLang = useTranslation().i18n.language;

  const getLocalizedLink = useCallback(
    (urlKey: keyof typeof Urls.forLinks, ...params: (number | string)[]) => {
      const localizedUrl =
        activeLang === "pl"
          ? Urls.forLinks[urlKey].pl
          : `/${activeLang}${
              Urls.forLinks[urlKey].international
            }`;
      return `${localizedUrl}${
        params.length > 0 ? `/${params.join("/")}` : ""
      }`;
    },
    [activeLang]
  );

  return { getLocalizedLink };
}
