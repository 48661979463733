import {
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import useHttp from "../../hooks/useHttp";
import { BACKEND_URL } from "../../utils/constants/backendContants";
import {
  Landmark,
  MapData,
  MapDataServerResponse,
  ServiceCategory,
  Store,
} from "../../types/mapData";
import { formatStringToUrl } from "../../utils/functions/string";
import { mapActions } from "../../redux/mapSlice";
import { ScreensaverSlide } from "../../types/screensaverData";
import { layoutActions } from "../../redux/layoutSlice";
import getSingleCookie from "../../utils/functions/getSingleCookie";
import setSingleCookie from "../../utils/functions/setSingleCookie";
import { Urls } from "../../utils/constants/frontendConstants";
import { useTranslation } from "react-i18next";

type LanguageMapDataProviderProps = {
  mapData?: MapData;
};

export default function LanguageMapDataProvider(
  props: LanguageMapDataProviderProps
) {
  const { mapData } = props;

  const location = useLocation();

  const { i18n } = useTranslation();

  const lang = useMemo(() => {
    if (/^\/en.*$/.test(location.pathname)) {
      return "en";
    } else {
      return null;
    }
  }, [location.pathname]);

  const [sendStoresRequest] = useHttp(
    `${BACKEND_URL}${lang ? `/${lang}` : ""}/scheme/data`,
    true
  );

  const [query] = useSearchParams();
  const kioskLocation = query.get("kiosk_location");

  const dispatch = useAppDispatch();

  const repeatRequestTimerRef = useRef<number | undefined>(undefined);


  function handleStoresResponse(response: Response) {
    if (!response.ok) throw new Error("Error while fetching map data");
    let mapData: MapDataServerResponse;
    let screensaverData: ScreensaverSlide[];
    return response
      .json()
      .then((data: MapDataServerResponse) => {
        data.store_has_category.forEach((storeHasCategory) => {
          const store = data.store.find(
            (store) => store.id === storeHasCategory.store_id
          ) as Store;
          if (store.category_ids === undefined) {
            store.category_ids = [];
          }
          if (store) {
            store.category_ids.push(storeHasCategory.category_id);
          }
        });
        data.service_category.forEach((service_from_server) => {
          const service = service_from_server as ServiceCategory;
          const localizedLink = lang
            ? `/${lang}${Urls.forLinks.serwisy.international}`
            : Urls.forLinks.serwisy.pl;
          service.service_url = `${localizedLink}/${
            service.id
          }-${formatStringToUrl(service_from_server.title)}`;
        });
        mapData = data;
        return Promise.resolve();
      })
      .then(() => {
        return fetch(
          `${BACKEND_URL}${lang ? `/${lang}` : ""}/kiosk-screensaver/index`
        )
          .then((response: Response) => {
            if (!response.ok)
              throw new Error("Error while fetching screensaver data");
            return response.json();
          })
          .then((data: ScreensaverSlide[]) => {
            screensaverData = data;
            return Promise.resolve();
          });
      })
      .then(() => {
        return fetch(`${BACKEND_URL}${lang ? `/${lang}` : ""}/api/poi`)
          .then((response: Response) => {
            if (!response.ok) throw new Error("Error while fetching poi data");
            return response.json();
          })
          .then((data:Landmark[]) => {
            dispatch(mapActions.setStores(mapData as MapData));
            dispatch(layoutActions.setScreensaverSlides(screensaverData));
            dispatch(mapActions.setLandmarksData(data));
            clearTimeout(repeatRequestTimerRef.current);
          });
      });
  }

  function handleStoresError(error: Error) {
    console.log("error handler",repeatRequestTimerRef.current)
    clearTimeout(repeatRequestTimerRef.current);
    repeatRequestTimerRef.current = setTimeout(
      () => {
        console.log("Repeat request");
        sendStoresRequest(handleStoresResponse, handleStoresError);
      },
      10000,
      undefined
    );
    console.log(error);
  }

  useEffect(() => {
    if (kioskLocation) {
      setSingleCookie(
        "kiosk_location",
        kioskLocation,
        new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        "/"
      );
      dispatch(mapActions.setKioskLocation(kioskLocation));
    }
  }, [kioskLocation]);

  useEffect(() => {
    const kioskLocation = getSingleCookie("kiosk_location");
    if (kioskLocation) {
      dispatch(mapActions.setKioskLocation(kioskLocation));
    }
    if (mapData) {
      return;
    }
    sendStoresRequest(handleStoresResponse, handleStoresError);
  }, [sendStoresRequest]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <Outlet />;
}
