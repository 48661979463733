import { Link, useNavigate } from "react-router-dom";
import GKLogo from "../../assets/images/logos/logo.png";
import SearchInput from "./SearchInput";
import Clock from "./Clock";
import ReturnButton from "./ReturnButton";
import { forwardRef } from "react";
import LanguageSwitch from "./LanguageSwitch";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

const TopBar = forwardRef<HTMLHeadElement,{}>((props, ref) => {

  const {getLocalizedLink} = useLocalizedLinks();

  const navigate = useNavigate();

  return (
      <header ref={ref} className="top-bar">
        <Link onClick={(event)=>{
            event.preventDefault();
            map.deselectStore()
            map.closeNavigation()
            navigate(getLocalizedLink("/"))
            map.panToDefaultLocation()
        }} to={""} className="gk-logo">
          <img src={GKLogo} alt="Galeria Krakowska" />
        </Link>
        <SearchInput />
        <div className="header-side-right">
          <ReturnButton/>
          <Clock/>
          <LanguageSwitch/>
        </div>
      </header>
  );
})

export default TopBar;
