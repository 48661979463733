import { useAppDispatch, useAppSelector } from "../../redux/store";
import { mapActions } from "../../redux/mapSlice";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

const MapLevelSidebar = forwardRef<HTMLDivElement,{}>((props, ref) => {
  const dispatch = useAppDispatch();

  const {t} = useTranslation();

  const activeLevel = useAppSelector((state) => {
    return state.mapState.activeLevel;
  });

  const kioskLevel = useAppSelector((state) => {
      return state.mapState.kioskLevel;
  });

  function moveToLevel(levelIndex: number) {
    map.moveToLevelIndex(levelIndex);
    dispatch(mapActions.setActiveLevel(levelIndex));
  }

  return (

      <div className="map-level-sidebar" ref={ref}>
        <div className={`level-item ${activeLevel === 4 ? "active" : ""}`} onClick={() => moveToLevel(4)}>
          3 <span>{t("poziom")}</span>
            { kioskLevel === 4 &&
            <span className="you-are-here">{t("Tu jesteś")}</span> }
        </div>
        <div className={`level-item ${activeLevel === 3 ? "active" : ""}`} onClick={() => moveToLevel(3)}>
          2 <span>{t("poziom")}</span>
            { kioskLevel === 3 &&
            <span className="you-are-here">{t("Tu jesteś")}</span> }
        </div>
        <div className={`level-item ${activeLevel === 2 ? "active" : ""}`} onClick={() => moveToLevel(2)}>
          1 <span>{t("poziom")}</span>
            { kioskLevel === 2 &&
            <span className="you-are-here">{t("Tu jesteś")}</span> }
        </div>
        <div className={`level-item ${activeLevel === 1 ? "active" : ""}`} onClick={() => moveToLevel(1)}>
          0 <span>{t("poziom")}</span>
            { kioskLevel === 1 &&
            <span className="you-are-here">{t("Tu jesteś")}</span> }
        </div>
        <div className={`level-item ${activeLevel === 0 ? "active" : ""}`} onClick={() => moveToLevel(0)}>
          -1 <span>{t("poziom")}</span>
            { kioskLevel === 0 &&
            <span className="you-are-here">{t("Tu jesteś")}</span> }
        </div>
      </div>

  );
})

export default MapLevelSidebar;
