import { useEffect, useState } from "react";
import useDocumentBlocades from "./hooks/useDocumentBlocades";
import { Route, Routes } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "./redux/store";
import Loading from "./components/shared/Loading";
import MapPage from "./pages/MapPage";
import { keyboardActions } from "./redux/keyboardSlice";
import KioskMap from "./components/map/KioskMap";
import ScreensaverPage from "./pages/ScreensaverPage";
import PromotionsPage from "./pages/PromotionsPage";
import LanguageMapDataProvider from "./components/shared/LanguageMapDataProvider";
import { Urls } from "./utils/constants/frontendConstants";
import useLocalizedLinks from "./hooks/useLocalizedLinks";
import RedirectOnMoveWrapper from "./components/shared/RedirectOnMoveWrapper";
import NavigateToCarMap from "./components/map/NavigateToCarMap";
import NavigateToCarPage from "./pages/NavigateToCarPage";
import StandSlidesPage from "./pages/StandSlidesPage";

function App() {
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useDocumentBlocades();

  const { getLocalizedLink } = useLocalizedLinks();

  const dispatch = useAppDispatch();

  const mapData = useAppSelector((state) => {
    return state.mapState.mapData;
  });

  const keyboardVisible = useAppSelector((state) => {
    return state.keyboardState.visible;
  });

  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      event.stopPropagation();
      if (keyboardVisible === true) {
        dispatch(keyboardActions.hideKeyboard());
      }
    });
  }, [keyboardVisible]);

  return (
    <Routes>
      <Route
        element={<StandSlidesPage />}
        path={Urls.forRouter.pl.standSlider}
      ></Route>
      <Route
        element={
          <StandSlidesPage
            rotateSlider={true}
            showTopImage={true}
            showBottomImage={true}
          />
        }
        path={Urls.forRouter.pl.verticalStandSlider}
      ></Route>
      <Route element={<LanguageMapDataProvider mapData={mapData} />}>
        <Route
          element={
            <NavigateToCarMap
              onMapLoaded={() => {
                setIsAppLoaded(true);
              }}
              mapData={mapData}
            />
          }
        >
          <Route
            path={Urls.forRouter.pl.navigateToCar}
            element={
              isAppLoaded ? (
                <NavigateToCarPage />
              ) : (
                <Loading coverParent={true} />
              )
            }
          />
          <Route
            path={Urls.forRouter.en.navigateToCar}
            element={
              isAppLoaded ? (
                <NavigateToCarPage />
              ) : (
                <Loading coverParent={true} />
              )
            }
          />
        </Route>
        <Route
          element={
            <KioskMap
              onMapLoaded={() => {
                setIsAppLoaded(true);
              }}
              mapData={mapData}
            />
          }
        >
          <Route
            element={
              <RedirectOnMoveWrapper
                preventableRedirectUrl={getLocalizedLink("screensaver")}
                immediateRedirectUrl={getLocalizedLink("/")}
              />
            }
          >
            <Route
              path={Urls.forRouter.pl.screensaverRoute}
              element={<ScreensaverPage />}
            />
            {isAppLoaded &&
              Urls.forRouter.pl.mapPageRoutes.map((path) => {
                return <Route key={path} path={path} element={<MapPage />} />;
              })}
            {isAppLoaded &&
              Urls.forRouter.pl.promotionRoutes.map((path) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <MapPage />
                        <PromotionsPage />
                      </>
                    }
                  />
                );
              })}
            {isAppLoaded &&
              Urls.forRouter.en.mapPageRoutes.map((path) => {
                return <Route key={path} path={path} element={<MapPage />} />;
              })}
            {isAppLoaded &&
              Urls.forRouter.en.promotionRoutes.map((path) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <MapPage />
                        <PromotionsPage />
                      </>
                    }
                  />
                );
              })}
            <Route
              path={Urls.forRouter.en.screensaverRoute}
              element={<ScreensaverPage />}
            />
          </Route>
          <Route path={Urls.forRouter.pl.loaderRoute} element={<Loading />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
