import { initializeApp } from "firebase/app";
import { getAnalytics, initializeAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAYQaKhFcPLrjyZ0-r05xHPL456rFT9e0o",
  authDomain: "mobi-galeriakrakowska.firebaseapp.com",
  projectId: "mobi-galeriakrakowska",
  storageBucket: "mobi-galeriakrakowska.appspot.com",
  messagingSenderId: "88855183045",
  appId: "1:88855183045:web:45cea70b334e603e093aac",
  measurementId: "G-JV3NV8T4VJ"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = initializeAnalytics(firebaseApp,{
  config:{
    send_page_view: false
  }
});