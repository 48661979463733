import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { layoutActions } from "../redux/layoutSlice";

export default function useDynamicBlocades(
  dynamicElementRef: React.RefObject<HTMLElement | null>,
  observeTwoLevels?: boolean
) {
  const browserLocation = window.location;
  const navigateRef = useRef(useNavigate());
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const mutationObserverRef = useRef<MutationObserver | null>(null);

  const blockLinkHandler = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const anchorTagElement = event.currentTarget as HTMLAnchorElement | null;
      if (anchorTagElement === null) return;
      const url = new URL(anchorTagElement.href);
      if (url.host === browserLocation.host) {
        navigateRef.current(url.pathname + url.search);
      } else {
        dispatch(layoutActions.showSnackbar(t("Funkcja niedostępna w kiosku")));
        return;
      }
    },
    [browserLocation.host,dispatch,t]
  );

  useEffect(() => {
    if (!dynamicElementRef.current) return;
    if (!mutationObserverRef.current) {
      dynamicElementRef.current.querySelectorAll("a").forEach((element) => {
        element.addEventListener("click", blockLinkHandler);
      });
      mutationObserverRef.current = new MutationObserver(
        (records, observer) => {
          records.forEach((mutation) => {
            if (mutation.addedNodes.length === 0) return;
            if (
              observeTwoLevels &&
              dynamicElementRef.current !== mutation.target &&
              dynamicElementRef.current !== mutation.target.parentElement
            )
              return;
            mutation.addedNodes.forEach((node) => {
              if (node.nodeType !== Node.ELEMENT_NODE) return;
              if ((node as HTMLElement).nodeName === "A") {
                (node as HTMLElement).addEventListener(
                  "click",
                  blockLinkHandler
                );
              }
              if ((node as HTMLElement).querySelector("a")) {
                (node as HTMLElement)
                  .querySelectorAll("a")
                  .forEach((element) => {
                    element.addEventListener("click", blockLinkHandler);
                  });
              }
            });
          });
        }
      );
    }
    mutationObserverRef.current.observe(dynamicElementRef.current, {
      childList: true,
      subtree: observeTwoLevels,
    });
    return () => {
      mutationObserverRef.current?.disconnect();
      mutationObserverRef.current = null;
    };
  }, [blockLinkHandler, dynamicElementRef, observeTwoLevels]);
}
