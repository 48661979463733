import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Clock() {

  const {i18n} = useTranslation();

  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [date, setDate] = useState(
    new Date().toLocaleDateString(i18n.language, {
      weekday: "long",
      month: "long",
      day: "numeric",
    })
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
      setDate(
        new Date().toLocaleDateString(i18n.language, {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      );
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="date-time-container">
      <div className="time">{time}</div>
      <div className="date">{date}</div>
    </div>
  );
  // return  null
}
