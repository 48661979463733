import { useTranslation } from "react-i18next";

type ShopParamsPanelProps = {
  name: string;
  level: string;
  code: string;
  categories: { imageUrl: string; title: string }[];
  openHours?: string;
  phone?: string;
  website?: string;
};

export default function ShopParamsPanel(props: ShopParamsPanelProps) {
  const { name, level, code, categories, openHours, phone, website } = props;

  const {t} = useTranslation();

  return (
    <div className="shop-params">
      <div className="general-params">
        <div className="name">{name}</div>
        <div className="categories">
          {categories.map((category, index) => (
            <div className="category" key={index}>
              <img src={category.imageUrl} alt={category.title} />
              <span>{category.title}</span>
            </div>
          ))}
        </div>
        <div className="additional-params">
          {openHours && <div className="open-hours">
            {t("otwarte")}: <span>{openHours}</span>
          </div>}
          {phone && (
            <div className="phone">
              tel. <span>{phone}</span>
            </div>
          )}
          {website && (
            <div className="website">
              <span>{website}</span>
            </div>
          )}
        </div>
      </div>
      <div className="local-params">
        <div className="level">
          {t("Poziom")} <span>{level}</span>
        </div>
        <div className="local">
          {t("lokal")} <span>{code}</span>
        </div>
      </div>
    </div>
  );
}
