import { Swiper, SwiperSlide } from "swiper/react";
import { useAppSelector } from "../redux/store";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { BACKEND_URL } from "../utils/constants/backendContants";

export default function ScreensaverPage() {
  const slides = useAppSelector((state) => {
    return state.layoutState.screensaver.slides;
  });

  return (
    <div className="screensaver">
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        effect="fade"
        autoplay={{ delay: 6000, disableOnInteraction: false }}
        allowTouchMove={false}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <div
                className="screensaver-slide"
                style={{
                  backgroundImage: `url(${BACKEND_URL}/${slide.image})`,
                }}
              >
                <div className="screensaver-slide-content">
                  <h1>
                    {slide.title.slice(0, slide.title.indexOf(" "))}
                    <br />
                    <strong>
                      {slide.title.slice(
                        slide.title.indexOf(" ") + 1,
                        slide.title.length
                      )}
                    </strong>
                  </h1>
                  <span>{slide.intro}</span>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
