import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { isLetter } from "../../../../utils/functions/string";
import ScrollToLetters from "../shared/ScrollToLetters";
import ShopsCategories from "./ShopsCategories";
import ShopsListWithLetterSections from "../shared/ShopsListWithLetterSections";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableList from "../../../shared/ScrollableList";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

export default function Shops() {
  const [activeCategoryId, setActiveCategoryId] = useState<string>("");

  const mapData  = useAppSelector((state) => {
    return state.mapState.mapData;
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { changePopupHeader } = useContext(ContentPopupContext);

  const scrollableShopsListRef = useRef<HTMLUListElement | null>(null);
  useDynamicBlocades(scrollableShopsListRef, true);

  const logPageViewEvent = useLogPageViewEvent();

  const store_category = mapData?.store_category;

  useEffect(()=>{
    const activeCategory = store_category?.find((category)=>{
      return category.id === activeCategoryId
    })
    logPageViewEvent(activeCategory?.title || "Sklepy")
  },[logPageViewEvent,store_category,activeCategoryId])

  useLayoutEffect(() => {
    changePopupHeader(t("Sklepy"), "icm_zakupy");
  }, []);


  let filteredStores = useMemo(() => {
    if(!mapData) return [];
    if (activeCategoryId === "") {
      return mapData.store;
    }
    return mapData.store.filter((store) => {
      return store.category_ids?.includes(activeCategoryId);
    });
  }, [mapData, activeCategoryId]);

  if (!mapData) return null;

  const letters: string[] = [];

  filteredStores.forEach((store) => {
    if (!isLetter(store.name[0]) && letters.indexOf("#") === -1) {
      letters.push("#");
    } else if (letters.indexOf(store.name[0].toUpperCase()) === -1) {
      letters.push(store.name[0].toUpperCase());
    }
  });

  function navigateButtonClickHandler(code: string) {
    navigate("/?navigate_to_store=" + code);
  }

  return (
    <div className="shops-container">
      <div className="shops-categories-container">
        <h3 className="shops-categories-header">{t("Kategorie sklepów")}</h3>
        <ScrollableList>
          <ShopsCategories
            shopsCategories={mapData.store_category}
            activeCategoryId={activeCategoryId}
            onCategoryClick={(category) => {
              setActiveCategoryId(category);
            }}
          />
        </ScrollableList>
      </div>
      <ScrollableList
        ref={scrollableShopsListRef}
        customClass="shops-list-scrollable-container"
      >
        <ShopsListWithLetterSections
          letters={letters}
          shops={filteredStores}
          onNavigateClick={navigateButtonClickHandler}
        />
      </ScrollableList>
      <ScrollableList>
        <ScrollToLetters letters={letters} />
      </ScrollableList>
    </div>
  );
}
