
import { Store } from "../../../../types/mapData";
import { isLetter } from "../../../../utils/functions/string";
import { Fragment } from "react/jsx-runtime";
import ShopListItem from "../../../shared/ShopListItem";
import { useTranslation } from "react-i18next";

type ShopsListWithLetterSectionsProps = {
  letters: string[];
  shops: Store[];
  onNavigateClick: (code: string) => void;
};

export default function ShopsListWithLetterSections(
  props: ShopsListWithLetterSectionsProps
) {
  const { letters, shops, onNavigateClick } = props;

  const {t} = useTranslation();

  return (
    <>
      {letters.length !== 0 ? (
        letters.map((letter) => {
          return (
            <Fragment key={letter}>
              <div
                className="letter-section-header"
                id={`letter-section-${letter}`}
              >
                {letter}
              </div>
              <ul className="shops-list">
                {shops.map((store) => {
                  if (
                    (letter === "#" && !isLetter(store.name[0])) ||
                    store.name[0].toUpperCase() === letter
                  ) {
                    return (
                      <ShopListItem
                        key={store.id}
                        id={store.id}
                        name={store.name}
                        subtitle={store.phone ? `tel. ${store.phone}` : ""}
                        hasPromotions={
                          !!store.promotions &&
                          store.promotions.length > 0
                        }
                        level={store.level}
                        code={store.code}
                        onNavigateClick={onNavigateClick}
                        logo={store.logo_list}
                      />
                    );
                  }
                  return null;
                })}
              </ul>
            </Fragment>
          );
        })
      ) : (
        <div className="no-shops">{t("Brak sklepów w wybranej kategorii")}</div>
      )}
    </>
  );
}
