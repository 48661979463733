import { useLocation } from "react-router-dom";
import BottomBar from "../components/bottomBar/BottomBar";
import TopBar from "../components/topBar/TopBar";
import MenuSidebar from "../components/menuSidebar/MenuSidebar";
import MapLevelSidebar from "../components/mapSidebar/MapLevelSidebar";
import ContentPopup from "../components/contentPopup/ContentPopup";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import Shops from "../components/contentPopup/popupContents/shops/Shops";
import Snackbar from "../components/shared/Snackbar";
import Foods from "../components/contentPopup/popupContents/foods/Foods";
import Services from "../components/contentPopup/popupContents/services/Services";
import ShopDetail from "../components/contentPopup/popupContents/shops/ShopDetail";
import Promotion from "../components/contentPopup/popupContents/promotions/Promotion";
import News from "../components/contentPopup/popupContents/news/News";
import Events from "../components/contentPopup/popupContents/events/Events";
import Landmarks from "../components/contentPopup/popupContents/landmarks/Landmarks";
import Landmark from "../components/contentPopup/popupContents/landmarks/Landmark";
import Event from "../components/contentPopup/popupContents/events/Event";
import NewsDetail from "../components/contentPopup/popupContents/news/NewsDetail";
import GiftCard from "../components/contentPopup/popupContents/gift/GiftCard";
import Parking from "../components/contentPopup/popupContents/parking/Parking";
import usePageBlocades from "../hooks/usePageBlocades";
import useLocalizedLinks from "../hooks/useLocalizedLinks";
import Wifi from "../components/contentPopup/popupContents/wifi/Wifi";
import Pram from "../components/contentPopup/popupContents/pram/Pram";
import TaxFree from "../components/contentPopup/popupContents/taxfree/TaxFree";
import useLogNewUserEvent from "../hooks/useLogNewUserEvent";
import Application from "../components/contentPopup/popupContents/application/Application";

export default function MapPage() {
  const location = useLocation();

  const [deferredPathname, setDeferredPathname] = useState(location.pathname);

  const topBarRef = useRef<HTMLHeadElement | null>(null);
  const bottomBarRef = useRef<HTMLDivElement | null>(null);
  const popupMenuContainerRef = useRef<HTMLDivElement | null>(null);
  const mapLevelSidebarRef = useRef<HTMLDivElement | null>(null);

  const {getLocalizedLink} = useLocalizedLinks();

  usePageBlocades([
    topBarRef,
    bottomBarRef,
    popupMenuContainerRef,
    mapLevelSidebarRef,
  ]);

  useLogNewUserEvent();

  const popupContentToRenderRef = useRef<{
    element: ReactElement | null;
  }>({ element: null });

  const isPopupVisible = location.pathname !== getLocalizedLink("/");

  useMemo(() => {
    if (deferredPathname === getLocalizedLink("sklepy")) {
      popupContentToRenderRef.current = {
        element: <Shops />,
      };
    } else if ((new RegExp(`^\\${getLocalizedLink("sklepy")}\\/\\d+$`)).test(deferredPathname)) {
      popupContentToRenderRef.current = {
        element: <ShopDetail />,
      };
    } else if (deferredPathname === getLocalizedLink("jedzenie")) {
      popupContentToRenderRef.current = {
        element: <Foods />,
      };
    } else if (deferredPathname === getLocalizedLink("serwisy")) {
      popupContentToRenderRef.current = {
        element: <Services />,
      };
    } else if (
      (new RegExp(`^\\${getLocalizedLink("promocje")}\\/\\d+$`)).test(deferredPathname) ||
      (new RegExp(`^\\${getLocalizedLink("promocja")}\\/\\d+$`)).test(deferredPathname)
    ) {
      popupContentToRenderRef.current = {
        element: <Promotion />,
      };
    } else if (deferredPathname === getLocalizedLink("aktualnosci")) {
      popupContentToRenderRef.current = {
        element: <News />,
      };
    } else if (deferredPathname === getLocalizedLink("wydarzenia")) {
      popupContentToRenderRef.current = {
        element: <Events />,
      };
    } else if (deferredPathname === getLocalizedLink("krakow")) {
      popupContentToRenderRef.current = {
        element: <Landmarks />,
      };
    } else if ((new RegExp(`^\\${getLocalizedLink("krakow")}\\/\\d+$`)).test(deferredPathname)) {
      popupContentToRenderRef.current = {
        element: <Landmark />,
      };
    } else if ((new RegExp(`^\\${getLocalizedLink("wydarzenia")}\\/\\d+$`)).test(deferredPathname)) {
      popupContentToRenderRef.current = {
        element: <Event />,
      };
    } else if ((new RegExp(`^\\${getLocalizedLink("aktualnosci")}\\/\\d+$`)).test(deferredPathname)) {
      popupContentToRenderRef.current = {
        element: <NewsDetail />,
      };
    } else if (deferredPathname === getLocalizedLink("karta-podarunkowa")) {
      popupContentToRenderRef.current = {
        element: <GiftCard />,
      };
    } else if (deferredPathname ===getLocalizedLink("parking")) {
      popupContentToRenderRef.current = {
        element: <Parking />,
      };
    } else if((new RegExp(`^\\${getLocalizedLink("serwisy")}\\/40-[a-zA-Z0-9-]+$`)).test(deferredPathname)) {
      popupContentToRenderRef.current = {
        element: <Wifi />,
      };
    }
    else if((new RegExp(`^\\${getLocalizedLink("serwisy")}\\/44-[a-zA-Z0-9-]+$`)).test(deferredPathname)){
      popupContentToRenderRef.current = {
        element: <Pram />,
      };
    }
    else if(deferredPathname === getLocalizedLink("tax-free")){
      popupContentToRenderRef.current = {
        element: <TaxFree />,
      };
    }
    else if(deferredPathname === getLocalizedLink("aplikacja")){
      popupContentToRenderRef.current = {
        element: <Application />,
      };
    }
     else {
      popupContentToRenderRef.current = { element: null };
    }
  }, [deferredPathname,getLocalizedLink]);

  useEffect(() => {
    let timer: null | number = null;
    if (!isPopupVisible) {
      timer = setTimeout(
        () => {
          setDeferredPathname(location.pathname);
        },
        200,
        []
      );
    } else {
      setDeferredPathname(location.pathname);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isPopupVisible, location.pathname]);

  return (
    <>
      <BottomBar ref={bottomBarRef} />
      <TopBar ref={topBarRef} />
      <div ref={popupMenuContainerRef} className="popup-menu-container">
        <div className="relative-container">
          <ContentPopup
            isVisible={
              isPopupVisible && !!popupContentToRenderRef.current.element
            }
          >
            {popupContentToRenderRef.current.element}
          </ContentPopup>
          <MenuSidebar />
        </div>
      </div>
      <MapLevelSidebar ref={mapLevelSidebarRef} />
      <Snackbar />
    </>
  );
}
