import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentPopupContext } from "../../ContentPopup";
import useHttp from "../../../../hooks/useHttp";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import { News } from "../../../../types/mapData";
import { useAppSelector } from "../../../../redux/store";
import SimpleDetail from "../shared/SimpleDetail";
import Loading from "../../../shared/Loading";
import { useTranslation } from "react-i18next";



export default function NewsDetail() {

    const { newsId } = useParams();

    const [description, setDescription] = useState<string | null>(null);
  
    const {changePopupHeader} = useContext(ContentPopupContext)

    const {t,i18n} = useTranslation();
  
    const news = useAppSelector((state) => {
        return state.mapState.mapData?.news;
      })?.find((news) => news.id === newsId);

    const [sendRequest, isLoading] = useHttp(
      `${BACKEND_URL}${i18n.language !== "pl" ? `/${i18n.language}` :""}/${`scheme/event-detail/id/${newsId}`}`
    );
  
    function handleResponse(response: Response) {
      if (!response.ok) {
        throw new Error("Could not download news data");
      }
      return response.json().then((data: News & {description: string}) => {
        setDescription(data.description);
      });
    }
  
    function handleError(error: Error) {
      console.error(error);
    }
  
    useEffect(() => {
      changePopupHeader(news?.name || t("Aktualności"), "icm_aktualnosci")
      if (newsId) {
        sendRequest(handleResponse, handleError);
      }
    }, []);
  
    if (!news) return null;
  
    return (
      <SimpleDetail image={news.thumb} addDate={news.date_add.split(" ")[0]}>
        {!isLoading && description !== undefined && description !== null ? (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        ) : (
          <Loading />
        )}
      </SimpleDetail>
    );
}