import { useContext, useEffect } from "react";
import SimpleDetail from "../shared/SimpleDetail";
import { ContentPopupContext } from "../../ContentPopup";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";

export default function Wifi() {
  const { changePopupHeader } = useContext(ContentPopupContext);

  const application = useAppSelector((state) => {
    return state.mapState.mapData?.mobile_app;
  });

  const { t } = useTranslation();

  useEffect(() => {
    changePopupHeader(t("Aplikacja mobilna"), "icm_informacja");
  }, []);

  if (!application) return null;

  return (
    <SimpleDetail
      image={application.image || undefined}
      addDate={application.date_add.split(" ")[0]}
      description={application.content}
        customClass="wifi"
    />
  );
}
