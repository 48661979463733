import { Outlet } from "react-router-dom";
import useRedirectOnMove from "../../hooks/useRedirectOnMove";

type RedirectOnMoveWrapperProps = {
  preventableRedirectUrl: string;
  immediateRedirectUrl: string;
};

export default function RedirectOnMoveWrapper(props: RedirectOnMoveWrapperProps) {
    const { preventableRedirectUrl, immediateRedirectUrl } = props;

  useRedirectOnMove(
    120000,
    preventableRedirectUrl,
    immediateRedirectUrl
  );

  return <Outlet/>
}
