import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import Divider from "../../../shared/Divider";
import ShopParamsPanel from "../shared/ShopParamsPanel";
import Button from "../../../shared/Button";
import Badge from "../../../shared/Badge";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useHttp from "../../../../hooks/useHttp";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableContainer from "../../../shared/ScrollableContainer";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

type PromotionServerResponse = {
  content: string;
  date_add: string;
  id: number;
  image: string;
  intro: string;
  title: string;
};

export default function Promotion() {
  const { promotionId } = useParams();

  const [promotionDescription, setPromotionDescription] = useState<string>("");

  const { changePopupHeader } = useContext(ContentPopupContext);

  const { t, i18n } = useTranslation();

  const [sendRequest, isLoading] = useHttp(
    `${BACKEND_URL}${
      i18n.language !== "pl" ? `/${i18n.language}` : ""
    }/scheme/promotion-detail/id/${promotionId}`,
    true
  );

  const { getLocalizedLink } = useLocalizedLinks();

  const navigate = useNavigate();

  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const relatedStoresButtonsContainerRef = useRef<HTMLDivElement | null>(null);

  useDynamicBlocades(descriptionRef);
  useDynamicBlocades(relatedStoresButtonsContainerRef);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(() => {
    logPageViewEvent("Promocja");
  }, [logPageViewEvent]);

  const mapData = useAppSelector((state) => {
    return state.mapState.mapData;
  });

  const promotionData = mapData?.promotions.find((promotion) => {
    return promotion.id === promotionId;
  });

  const storeData = mapData?.store.find((store) => {
    return store.id === promotionData?.store_id;
  });

  function handleResponse(response: Response) {
    if (!response.ok) {
      throw new Error("Failed to fetch promotion data");
    }
    return response.json().then((data: PromotionServerResponse) => {
      setPromotionDescription(data.content);
    });
  }

  function handleError(error: Error) {
    console.error(error.message);
  }

  useLayoutEffect(() => {
    if (storeData) {
      changePopupHeader(storeData.name, "", {
        storeId: storeData.id,
        imageUrl: `${BACKEND_URL}/${storeData.logo_list}`,
      });
    }
  }, [storeData]);

  useEffect(() => {
    if (promotionId === undefined) return;
    sendRequest(handleResponse, handleError);
  }, [promotionId]);

  if (!mapData || !promotionData || !storeData) return null;

  const storeCategories = mapData.store_category
    .filter((category) => {
      return storeData.category_ids?.includes(category.id);
    })
    .map((category) => {
      return {
        imageUrl: `${BACKEND_URL}/${category.logo}`,
        title: category.title,
      };
    });

  return (
    <ScrollableContainer customClass="promotion-container">
      <Badge icon="icm_promocje">{t("Promocja")}</Badge>
      <img
        className="promotion-image"
        src={`${BACKEND_URL}/${promotionData.image}`}
        alt={promotionData.title}
      />

      <Divider />

      <ShopParamsPanel
        name={storeData.name}
        level={storeData.level}
        code={storeData.code}
        categories={storeCategories}
        // openHours={"10:00 - 20:00"}
        phone={storeData.phone}
        website={storeData.website}
      />

      <div className="navigation-buttons">
        <Button
          variant="primary"
          icon={"navigate"}
          fullWidth={true}
          onClick={() => {
            navigate(`/?navigate_to_store=${storeData.code}`);
          }}
        >
          {t("Nawiguj")}
        </Button>

        {storeData.related_stores && storeData.related_stores.length > 0 ? (
          <>
            <span className="related-shops-info">
              {t("Wybrane miejsca dostępne są również")}:
            </span>
            <div
              className="level-buttons"
              ref={relatedStoresButtonsContainerRef}
            >
              {storeData.related_stores.map((store) => {
                return (
                  <Button
                    key={store.id}
                    variant="primary"
                    outlined={true}
                    fullWidth={true}
                    link={getLocalizedLink("sklepy", store.id)}
                  >
                    {t("poziom")} {store.level}
                  </Button>
                );
              })}
            </div>
          </>
        ) : null}
      </div>

      <div
        ref={descriptionRef}
        className="description-container"
        dangerouslySetInnerHTML={{
          __html: isLoading
            ? '<div class="loader-container"><div class="loader"></div></div>'
            : promotionDescription,
        }}
      ></div>
    </ScrollableContainer>
  );
}
