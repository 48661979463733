import { useContext, useEffect, useRef } from "react"
import { useAppSelector } from "../../../../redux/store"
import { ContentPopupContext } from "../../ContentPopup"
import ScrollableList from "../../../shared/ScrollableList"
import SimpleListItem from "../../../shared/SimpleListItem"
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades"
import { useTranslation } from "react-i18next"
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks"
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent"



export default function Landmarks(){

    const {changePopupHeader} = useContext(ContentPopupContext)

    const landmarks = useAppSelector((state)=>{
        return state.mapState.landmarksData
    })

    const scrollableListRef = useRef<HTMLUListElement | null>(null);

    const {t} = useTranslation();

    const {getLocalizedLink} = useLocalizedLinks();

    useDynamicBlocades(scrollableListRef);

    const logPageViewEvent = useLogPageViewEvent();

    useEffect(()=>{
      logPageViewEvent("Kraków")
    },[logPageViewEvent])

    useEffect(()=>{
        changePopupHeader(t("Kraków"),"icm_krakow")
    },[]);

    if(!landmarks) return null

    return <ScrollableList ref={scrollableListRef} customClass="landmarks-container">
        {landmarks.map((landmark)=>{
            return <SimpleListItem
                title={landmark.name}
                subtitle={landmark.description.slice(0,200)}
                imageUrl={landmark.thumb}
                key={landmark.id}
                topInfo={{text:t("Atrakcja"), icon:"icm_krakow"}}
                link={getLocalizedLink("krakow",landmark.id)}
            />
            
        })}
    </ScrollableList>
}