import { useContext, useEffect } from "react";
import SimpleDetail from "../shared/SimpleDetail";
import { ContentPopupContext } from "../../ContentPopup";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";

export default function Pram() {
  const { changePopupHeader } = useContext(ContentPopupContext);

  const pram = useAppSelector((state) => {
    return state.mapState.mapData?.pram;
  });

  const { t } = useTranslation();

  useEffect(() => {
    changePopupHeader(t("Wynajem wózków"), "kid-car-rental");
  }, []);

  if (!pram) return null;

  return (
    <SimpleDetail
      image={pram.image || undefined}
      addDate={pram.date_add.split(" ")[0]}
      description={pram.content}
        customClass="pram"
    />
  );
}
