import { MutableRefObject, useState } from "react";
import { createPortal } from "react-dom";
import Keyboard, { KeyboardReactInterface } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

type LocalizedKeyboardProps = {
  onKeyPress?: (button: string) => void;
  onChange: (input: string) => void;
  onEnter?: () => void;
  visible: boolean;
  forwardedRef: MutableRefObject<KeyboardReactInterface | null>;
};

const LocalizedKeyboard = (props: LocalizedKeyboardProps) => {
  const { onKeyPress, onChange, forwardedRef, onEnter, visible } = props;

  const [layoutName, setLayoutName] = useState("default");

  const keyboardLayoutChangeHandler = (button: string) => {
    if (button === "{tab}") return;
    if (button === "{enter}") {
      onEnter && onEnter();
    }
    if (button === "{shift}" || button === "{lock}") {
      switch (layoutName) {
        case "alt":
          setLayoutName("shift_alt");
          break;
        case "shift_alt":
          setLayoutName("alt");
          break;
        case "shift":
          setLayoutName("default");
          break;
        case "default":
          setLayoutName("shift");
          break;
        default:
          setLayoutName("default");
          break;
      }
    } else if (button === "{alt}") {
      switch (layoutName) {
        case "alt":
          setLayoutName("default");
          break;
        case "shift_alt":
          setLayoutName("shift");
          break;
        case "shift":
          setLayoutName("shift_alt");
          break;
        case "default":
          setLayoutName("alt");
          break;
        default:
          setLayoutName("default");
          break;
      }
    }
  };

  const display = {
    "{enter}": "enter",
  };

  const layout = {
    default: [
      " 1 2 3 4 5 6 7 8 9 0 {bksp}",
      " q w e r t y u i o p ",
      "{lock} a s d f g h j k l {enter}",
      "{shift} z x c v b n m {alt}",
      "{space}",
    ],
    shift: [
      " 1 2 3 4 5 6 7 8 9 0 {bksp}",
      " Q W E R T Y U I O P ",
      "{lock} A S D F G H J K L {enter}",
      "{shift} Z X C V B N M {alt}",
      "{space}",
    ],
    alt: [
      " 1 2 3 4 5 6 7 8 9 0 {bksp}",
      " q w ę r t y u i ó p ",
      "{lock} ą ś d f g h j k ł {enter}",
      "{shift} ż ź ć v b ć ń m {alt}",
      "{space}",
    ],
    shift_alt: [
      " 1 2 3 4 5 6 7 8 9 0 {bksp}",
      " Q W Ę R T Y U I Ó P ",
      "{lock} Ą Ś D F G H J K Ł {enter}",
      "{shift} Ż Ź Ć V B Ń M {alt}",
      "{space}",
    ],
  };

  return createPortal(
    <div className={`keyboard-container ${visible ? "visible" : ""}`}>
      <Keyboard
        display={display}
        mergeDisplay={true}
        layout={layout}
        layoutName={layoutName}
        disableCaretPositioning={true}
        keyboardRef={(r: KeyboardReactInterface) => {
          r.keyboardDOM.addEventListener("click", (event) => {
            event.stopPropagation();
          });
          
          forwardedRef.current = r
        }}
        onChange={onChange}
        onKeyPress={onKeyPress ? onKeyPress : keyboardLayoutChangeHandler}
      />
    </div>,
    document.body
  );
};

export default LocalizedKeyboard;
