import { PropsWithChildren } from "react";
import Icon from "./Icon";

type BadgeProps = {
  icon?: string;
} & PropsWithChildren;

export default function Badge(props: BadgeProps) {
  const { icon, children } = props;

  return (
    <div className="badge">
      {children}
      {icon && icon.trim().length !== 0 && <Icon name={icon} />}
    </div>
  );
}
