import { Link } from "react-router-dom";
import Icon from "./Icon";
import NoPhoto from "../../assets/images/utility/no-photo.png";
import { BACKEND_URL } from "../../utils/constants/backendContants";

type SimpleListItemProps = {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  containImage?: boolean;
  link?: string;
  customClass?: string;
  topInfo?: {
    text: string;
    icon?: string;
  };
  onClick?: () => void;
};

export default function SimpleListItem(props: SimpleListItemProps) {
  const { title, subtitle, imageUrl, link, topInfo, onClick, containImage, customClass } =
    props;

  const innerListItem = (
    <>
      <div className="logo-container">
        <img
          className={containImage ? "contain-image" : ""}
          src={imageUrl ? `${BACKEND_URL}/${imageUrl}` : NoPhoto}
          alt={props.title}
        />
      </div>
      <div className="data-container">
        {topInfo && (
          <div className="top-info">
            {topInfo.icon && <Icon name={topInfo.icon} />}
            <span>{topInfo.text}</span>
          </div>
        )}
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}}></div>}
      </div>
    </>
  );

  return (
    <>
      {link ? (
        <Link to={link} className={`simple-list-item ${customClass}`} onClick={onClick}>
          {innerListItem}
        </Link>
      ) : (
        <div className={`simple-list-item ${customClass}`} onClick={onClick}>
          {innerListItem}
        </div>
      )}
    </>
  );
}
