export function formatStringToUrl(str: string) {
  return normalizeString(str).toLowerCase().replace(/ /g, "-");
}

export function isLetter(char: string): boolean{
  return /^[A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż]$/.test(char);
}

export function normalizeString(str: string) {
  const regex = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g;
  return str.replace(regex, (match) => {
    switch (match) {
      case "ą":
        return "a";
      case "ć":
        return "c";
      case "ę":
        return "e";
      case "ł":
        return "l";
      case "ń":
        return "n";
      case "ó":
        return "o";
      case "ś":
        return "s";
      case "ź":
        return "z";
      case "ż":
        return "z";
      case "Ą":
        return "A";
      case "Ć":
        return "C";
      case "Ę":
        return "E";
      case "Ł":
        return "L";
      case "Ń":
        return "N";
      case "Ó":
        return "O";
      case "Ś":
        return "S";
      case "Ź":
        return "Z";
      case "Ż":
        return "Z";
      default:
        return match;
    }
  });
}
