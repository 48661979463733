import { StoreCategory } from "../../../../types/mapData";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";

type ShopsCategoriesProps = {
  shopsCategories: StoreCategory[];
  activeCategoryId: string;
  onCategoryClick: (categoryId: string) => void;
};

export default function ShopsCategories(props: ShopsCategoriesProps) {
  return (
    <ul className="shops-categories">
      {props.shopsCategories
        .filter((category) => {
          return category.parent_id !== null;
        }).sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        })
        .map((category) => {
          const isActive = category.id === props.activeCategoryId;
          return (
            <li
              className={isActive ? "active" : ""}
              key={category.id}
              onClick={(event) => {
                props.onCategoryClick(!isActive ? category.id : "");
              }}
            >
              <img src={`${BACKEND_URL}/${category.logo}`} alt="" />
              {category.title}
            </li>
          );
        })}
    </ul>
  );
}
