import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Landmark, MapData } from "../types/mapData";

type MapState = {
  mapLoaded: boolean;
  mapData?: MapData;
  activeLevel: number;
  kioskLevel: number;
  kioskLocation?: string;
  landmarksData?: Landmark[];
};

const initialState: MapState = {
  mapLoaded: false,
  mapData: undefined,
  landmarksData: undefined,
  activeLevel: 0,
  kioskLevel: -1,
  kioskLocation: undefined,
};

export const mapSlice = createSlice({
  initialState: initialState,
  name: "map",
  reducers: {
    setStores: (state, action: PayloadAction<MapData>) => {
      state.mapData = action.payload;
    },
    setActiveLevel: (state, action: PayloadAction<number>) => {
      state.activeLevel = action.payload;
    },
    setMapLoaded: (state) => {
      state.mapLoaded = true;
    },
    setKioskLocation: (state, action: PayloadAction<string>) => {
      state.kioskLocation = action.payload;
      let parts = state.kioskLocation.split(',')
      if (parts.length >= 3) {
        state.kioskLevel = parseInt(parts[2])
      }
    },
    setLandmarksData: (state, action: PayloadAction<Landmark[]>) => {
      state.landmarksData = action.payload;
    }
  },
});

export const mapActions = mapSlice.actions;
export default mapSlice.reducer;
