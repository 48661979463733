import { useContext, useEffect, useRef, useState } from "react";
import { ContentPopupContext } from "../../ContentPopup";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import ScrollableContainer from "../../../shared/ScrollableContainer";
import LandmarkPin from "../../../../assets/images/utility/landmarks-pin.svg";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import Button from "../../../shared/Button";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

function generateGoogleMapsLink(lat: number, lng: number) {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${lat},${lng}`)}`;
}

export default function Landmark() {
  const [showBigMap, setShowBigMap] = useState(false);

  const { changePopupHeader } = useContext(ContentPopupContext);

  const { landmarkId } = useParams();

  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  useDynamicBlocades(scrollableContainerRef);

  const { t } = useTranslation();

  const landmark = useAppSelector((state) => {
    return state.mapState.landmarksData;
  })?.find((landmark) => landmark.id === +(landmarkId ? landmarkId : ""));

  useEffect(() => {
    changePopupHeader(t("Kraków"), "icm_krakow");
  }, []);

  if (!landmark) return null;

  return (
    <ScrollableContainer ref={scrollableContainerRef} customClass="landmark-container">
      <div className="image-container">
        <div
          className={`map-image-container ${showBigMap ? "show" : ""}`}
          onClick={(event) => {
            event.stopPropagation();
            setShowBigMap(true);
          }}
        >
          <div className="pin-layer">
            <img
              className="map-image"
              src={`${BACKEND_URL}/${landmark.map_image}`}
              alt="map"
            />
            <img
              className="landmark-pin"
              src={LandmarkPin}
              alt="landmark-pin"
            />
            <Button
              customClass={`${showBigMap ? "show" : ""}`}
              variant="secondary"
              icon="close"
              equalPadding={true}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setShowBigMap(false);
              }}
            ></Button>
          </div>
        </div>
        <img
          className="landmark-image"
          src={`${BACKEND_URL}/${landmark.images[0]}`}
          alt={landmark.name}
        />
      </div>
      {landmark.latitude && landmark.longitude && <div className="qr-code-container">
        {t("Znajdź na Google Maps")}:
        <QRCode size={160} value={generateGoogleMapsLink(landmark.latitude,landmark.longitude)}/>
      </div>}
      <div className="description-container" dangerouslySetInnerHTML={{__html: landmark.description}}></div>
    </ScrollableContainer>
  );
}
