import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { mapActions } from "../../redux/mapSlice";
import { MapData, StorePopupData } from "../../types/mapData";
import Icon from "../shared/Icon";
import NavigationPanel from "./NavigationPanel";
import MapPointerPopup from "./MapPointerPopup";
import MapStorePopup from "./MapStorePopup";
import { layoutActions } from "../../redux/layoutSlice";
import { useTranslation } from "react-i18next";
import useDynamicBlocades from "../../hooks/useDynamicBlocades";
import useLogPageViewEvent from "../../hooks/useLogPageViewEvent";
import Map from "./Map";

type MapProps = {
  mapData?: MapData;
  onMapLoaded: () => void;
};

export default function KioskMap(props: MapProps) {
  const { mapData, onMapLoaded } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();

  const { t } = useTranslation();

  const kiosk_location = useAppSelector(
    (state) => state.mapState.kioskLocation
  );

  const [storePopupData, setStorePopupData] = useState<StorePopupData | null>(
    null
  );

  const [isNavigationActive, setNavigationActive] = useState<{
    active: boolean;
    storeName: string;
  }>({ active: false, storeName: "" });

  const locationPopupRef = useRef<HTMLDivElement | null>(null);
  const navigationHelperPopupRef = useRef<HTMLDivElement | null>(null);
  const storePopupRef = useRef<HTMLDivElement | null>(null);

  const navigate_to_store = query.get("navigate_to_store");
  const navigate_to_sprite = query.get("navigate_to_sprite");
  const show_store = query.get("show_store");

  const [navigationHelperPopupText, setNavigationHelperPopupText] =
    useState<string>("");

  useDynamicBlocades(storePopupRef);

  const navigateToSelectedObject = () => {
    let sprite = map.getSelectedSprite();
    if (sprite) {
      navigate("/?navigate_to_sprite=" + sprite.nodeName);
    } else {
      let store = map.getSelectedStore();
      if (store) {
        navigate("/?navigate_to_store=" + store.code);
      }
    }
  };

  const initHandlers = useCallback(() => {
    if (!mapData) return;

    map.onRouteNotFound = function () {
      dispatch(layoutActions.showSnackbar("Nie udało się wyznaczyć trasy"));
    };

    map.onNavigationHelperPopupTextChanged = function (text) {
      setNavigationHelperPopupText(text);
    };
    map.onNavigationHelperPopupHide = function () {
      if (navigationHelperPopupRef.current !== null) {
        navigationHelperPopupRef.current.classList.remove("show");
      }
    };
    map.onNavigationHelperPopupShow = function () {
      if (navigationHelperPopupRef.current !== null) {
        navigationHelperPopupRef.current.classList.add("show");
      }
    };
    map.onNavigationHelperPopupPositionChanged = function (x, y) {
      if (navigationHelperPopupRef.current) {
        navigationHelperPopupRef.current.style.left = x + "px";
        navigationHelperPopupRef.current.style.top = y + "px";
      }
    };

    map.onUpdateLocationPopup = function (x: number, y: number) {
      if (locationPopupRef.current) {
        locationPopupRef.current.style.left = x + "px";
        locationPopupRef.current.style.top = y + "px";
      }
    };
    map.onPopupLocationChanged = function (object, position) {
      if (storePopupRef.current !== null) {
        storePopupRef.current.style.left = position.x + "px";
        storePopupRef.current.style.top = position.y + "px";
      }
    };
    map.onStoreSelected = function (store) {
      if (!mapData) return;
      const storeData = mapData.store.find((s) => s.code === store.code);
      if (!storeData) return;
      const categories: string[] = [];
      storeData.category_ids?.forEach((categoryId) => {
        const category = mapData.store_category.find(
          (category) => category.id === categoryId
        );
        if (category) {
          categories.push(category.title);
        }
      });
      setStorePopupData({
        id: storeData.id,
        name: storeData.name,
        logoUrl: storeData.logo_list,
        code: storeData.code,
        categories: categories,
      });
    };
    map.onNavigationStarted = function () {
      if (storePopupRef.current !== null) {
        storePopupRef.current.classList.remove("show");
      }
    };
    map.onNavigationStopped = function () {
      setNavigationActive({ active: false, storeName: "" });
    };
    map.onStoreDeselected = function (store) {
      if (storePopupRef.current !== null) {
        storePopupRef.current.classList.remove("show");
      }
    };
    map.onSpriteClicked = function (sprite) {
      const spriteData = mapData.sprites[sprite.name];
      if (!spriteData) return;
      map.deselectStore();
      map.setPopupObject(sprite);
      setStorePopupData({
        name: spriteData.label,
        iconUrl: map.getSpriteIcon(spriteData.icon),
        code: sprite.name,
      });
    };
    map.onLevelDidChanged = function (index) {
      let levelIndex = map.getLevelIndex();
      let kioskLocation = map.getKioskLocation();
      if (
        kioskLocation &&
        locationPopupRef.current != null &&
        kioskLocation.levelIndex == levelIndex
      ) {
        locationPopupRef.current.classList.add("show");
      }
    };
    map.onLevelWillChange = function (currentIndex, newIndex) {
      map.deselectStore();
      map.hideStorePopup();
      map.changeCameraDistance(true, 45);
      dispatch(mapActions.setActiveLevel(newIndex));
    };
    map.showLocationPopup = function () {
      if (locationPopupRef.current != null) {
        locationPopupRef.current.classList.add("show");
      }
    };
    map.hideLocationPopup = function () {
      if (locationPopupRef.current != null) {
        locationPopupRef.current.classList.remove("show");
      }
    };
    map.hideStorePopup = function () {
      if (storePopupRef.current !== null) {
        storePopupRef.current.classList.remove("show");
      }
    };
  }, [
    locationPopupRef.current,
    storePopupRef.current,
    mapData,
    navigate_to_store,
  ]);

  const initKioskLocation = useCallback(() => {
    if (kiosk_location) {
      map.setKioskLocation(kiosk_location);
    }
  }, [kiosk_location]);

  useEffect(() => {
    if (navigate_to_store) {
      map.navigateToStore(navigate_to_store);
      const store = mapData?.store.find((s) => s.code === navigate_to_store);
      setNavigationActive({ active: true, storeName: store?.name || "" });
    }
    if (navigate_to_sprite) {
      map.navigateToSprite(navigate_to_sprite);
      setNavigationActive({ active: true, storeName: "" });
    }
    if (show_store) {
      map.showStoreWithCode(show_store, true);
    }
  }, [navigate_to_store, navigate_to_sprite, show_store]);

  useEffect(() => {
    if (
      storePopupRef.current &&
      storePopupData &&
      !storePopupRef.current.classList.contains("show")
    ) {
      storePopupRef.current.classList.add("show");
    }
  }, [storePopupData, storePopupRef.current]);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(() => {
    if (!mapData) return;
    const regexMatch = location.pathname.match(/(\d+)-[a-zA-Z0-9-]+$/);
    if (regexMatch) {
      const serviceId = regexMatch[1];
      const service = mapData.service_category.find(
        (category) => category.id === serviceId
      );
      if (service) {
        map.closeNavigation();
        map.setSpritesVisible(
          service.sprite_codes ? service.sprite_codes.split(",") : [],
          true,
          true
        );
        logPageViewEvent("Serwisy", { serviceName: service.title });
      }
    }
    return () => {
      map.setSpritesVisible([], false, false);
    };
  }, [location.pathname]);

  useEffect(() => {
    initHandlers();
  }, [initHandlers]);

  useEffect(() => {
    initKioskLocation();
  }, [initKioskLocation]);

  return (
    <>
      <Outlet />
      <div className="bar-paddings">
        <div className="popup-layer">
          <NavigationPanel
            onCloseNavigation={() => {
              navigate("/");
              map.closeNavigation();
            }}
            onChangeToWheelchairMode={(wheelchairMode) => {
              map.toggleNavigationWheelMode(wheelchairMode);
            }}
            isVisible={isNavigationActive.active}
            storeName={isNavigationActive.storeName}
          />
          <MapPointerPopup
            ref={locationPopupRef}
            customClass="kiosk-position-popup"
          >
            {t("Tu jesteś")}
          </MapPointerPopup>
          <MapPointerPopup
            ref={navigationHelperPopupRef}
            onClick={() => {
              map.changeLevelToNavigationNextStep();
            }}
            customClass="navigation-helper-popup"
          >
            {t(navigationHelperPopupText)} <Icon name={"chevron-right"} />
          </MapPointerPopup>
          <MapStorePopup
            name={storePopupData?.name}
            id={storePopupData?.id}
            iconUrl={storePopupData?.iconUrl}
            logoUrl={storePopupData?.logoUrl}
            code={storePopupData?.code}
            categories={storePopupData?.categories}
            customClass="store-location-popup"
            variant="white"
            showNavigation={!!kiosk_location}
            ref={storePopupRef}
            onNavigate={() => {
              navigateToSelectedObject();
            }}
          ></MapStorePopup>
          <Map
            mapData={mapData}
            kiosk_location={kiosk_location}
            onMapLoaded={onMapLoaded}
          />
        </div>
      </div>
    </>
  );
}
