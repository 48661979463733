import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { BACKEND_URL } from "../utils/constants/backendContants";
import { useEffect, useRef, useState } from "react";
import { ScreensaverSlide } from "../types/screensaverData";
import useHttp from "../hooks/useHttp";
import TopStandImage from "../assets/images/stand/GK-vertical-slider-header.png";
import BottomStandImage from "../assets/images/stand/GK-vertical-slider-bottom.png";

type StandSlidesPageProps = {
  rotateSlider?: boolean;
  showTopImage?: boolean;
  showBottomImage?: boolean;
};

export default function StandSlidesPage(props: StandSlidesPageProps) {
  const [slides, setSlides] = useState<ScreensaverSlide[]>([]);

  const [sendRequest] = useHttp(`${BACKEND_URL}/kiosk-screensaver/index`, true);

  const swiperRef = useRef<SwiperClass | null>(null);

  const [topImageLoaded, setTopImageLoaded] = useState(false);
  const [bottomImageLoaded, setBottomImageLoaded] = useState(false);

  function handleSlidesResponse(response: Response) {
    response.json().then((data: ScreensaverSlide[]) => {
      setSlides(data);
    });
  }

  function handleSlidesError(error: Error) {
    console.error(error);
  }

  useEffect(() => {
    sendRequest(handleSlidesResponse, handleSlidesError);
  }, [sendRequest]);

  

  return (
    <div
      className={`screensaver align-right ${
        props.rotateSlider ? "rotate-slider" : ""
      }`}
    >
      {props.showTopImage ? (
        <img
          className="stand-top"
          src={TopStandImage}
          onLoad={() => {
            setTopImageLoaded(true);
          }}
        ></img>
      ) : null}
      {(topImageLoaded && bottomImageLoaded) || !props.rotateSlider ? (
        <Swiper
          modules={[Autoplay, EffectFade]}
          slidesPerView={1}
          effect="fade"
          autoplay={{ delay: 6000, disableOnInteraction: false }}
          allowTouchMove={false}
          init={true}
          onSwiper={(swiper: SwiperClass) => {
            swiperRef.current = swiper;
          }}
        >
          {slides.filter((slide)=>{
            return !props.rotateSlider || slide.id != "6592";
          }).map((slide) => {
            return (
              <SwiperSlide key={slide.id}>
                <div
                  className="screensaver-slide"
                  style={
                    !props.rotateSlider
                      ? {
                          backgroundImage: `url(${BACKEND_URL}/${slide.image})`,
                        }
                      : {}
                  }
                >
                  {props.rotateSlider && <div
                    className="rotated-background"
                    style={{
                      backgroundImage: `url(${BACKEND_URL}/${slide.image})`,
                    }}
                  ></div>}
                  <div className="screensaver-slide-content">
                    <h1>
                      {slide.title.slice(0, slide.title.indexOf(" "))}
                      <br />
                      <strong>
                        {slide.title.slice(
                          slide.title.indexOf(" ") + 1,
                          slide.title.length
                        )}
                      </strong>
                    </h1>
                    <span>{slide.intro}</span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      {props.showBottomImage ? (
        <img
          className="stand-top"
          src={BottomStandImage}
          onLoad={() => {
            setBottomImageLoaded(true);
          }}
        ></img>
      ) : null}
    </div>
  );
}
