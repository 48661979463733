import { ChangeEvent, useMemo, useRef, useState } from "react";
import Icon from "../shared/Icon";
import LocalizedKeyboard from "../shared/LocalizedKeyboard";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { keyboardActions } from "../../redux/keyboardSlice";
import useDynamicBlocades from "../../hooks/useDynamicBlocades";
import ShopListItem from "../shared/ShopListItem";
import { ServiceCategory, Store } from "../../types/mapData";
import SimpleListItem from "../shared/SimpleListItem";
import { useTranslation } from "react-i18next";
import { KeyboardReactInterface } from "react-simple-keyboard";

export default function SearchInput() {
  const [searchValue, setSearchValue] = useState("");

  const {t} = useTranslation();

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const searchHintsListRef = useRef<HTMLUListElement | null>(null);

  useDynamicBlocades(searchHintsListRef);

  const isKeyboardVisible = useAppSelector((state) => {
    return state.keyboardState.visible;
  });

  let stores =
    useAppSelector((state) => {
      return state.mapState.mapData?.store;
    }) || [];

  let services =
    useAppSelector((state) => {
      return state.mapState.mapData?.service_category;
    }) || [];

  const dispatch = useAppDispatch();

  function showKeyboardHandler() {
    setSearchValue("");
    keyboardRef.current?.clearInput()
    dispatch(keyboardActions.showKeyboard());
  }

  const filteredHints = useMemo(() => {
    if (searchValue.length < 2) {
      return [];
    }
    const filteredStores = stores.filter((store) => {
      if (store.name.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }

      const keywords = store.keywords.split(",");

      for (const keyword of keywords) {
        if (keyword.trim().toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      }

      return false;
    });

    const filteredServices = services.filter((service) => {
      return service.title.toLowerCase().includes(searchValue.toLowerCase());
    });

    return [...filteredStores, ...filteredServices].sort((a, b) => {
      const aName = (a as any).name
        ? (a as Store).name.toLowerCase()
        : (a as ServiceCategory).title.toLowerCase();
      const bName = (b as any).name
        ? (b as Store).name.toLowerCase()
        : (b as ServiceCategory).title.toLowerCase();

      return aName > bName ? 1 : -1;
    });
  }, [searchValue, stores, services]);


  return (
    <>
      <div className="search-input">
        <div className="input-container">
          <input
            type="text"
            value={searchValue}
            placeholder={t("znajdź sklep")}
            onFocus={showKeyboardHandler}
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
          <button>
            <Icon name="search" />
          </button>
        </div>
        {isKeyboardVisible && (
          <div className="search-hints">
            {searchValue.length < 2 ? (
              <span className="search-info">{t("Wpisz co najmniej 2 litery")}.</span>
            ) : filteredHints.length === 0 ? (
              <span className="search-info">{t("Nie znaleziono sklepów")}.</span>
            ) : (
              <ul className="search-hints-list" ref={searchHintsListRef}>
                {filteredHints.map((hint, index) => {
                  if ((hint as any).name) {
                    const store = hint as Store;
                    return (
                      <ShopListItem
                        key={index}
                        logo={store.logo_list}
                        customClass="hint"
                        name={store.name}
                        id={store.id}
                        level={store.level}
                        code={store.code}
                      />
                    );
                  } else {
                    const service = hint as ServiceCategory;
                     return <SimpleListItem
                     key={index}
                      customClass="hint"
                      imageUrl={service.logo}
                      link={service.service_url}
                      title={service.title}
                      containImage={true}
                    />;
                  }
                })}
              </ul>
            )}
          </div>
        )}
      </div>
      <LocalizedKeyboard
        visible={isKeyboardVisible}
        forwardedRef={keyboardRef}
        onChange={setSearchValue}
      />
    </>
  );
}
