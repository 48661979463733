import { forwardRef, PropsWithChildren } from "react";

type ScrollableListProps = {
  customClass?: string;
  fullWidth?: boolean;
} & PropsWithChildren;

const ScrollableList = forwardRef<HTMLUListElement,ScrollableListProps>(function (props,ref) {
  
  return (
    <ul ref={ref}
      className={`scrollable-list ${props.customClass ? props.customClass :""} ${
        props.fullWidth ? "full-width" : ""
      }`}
    >
      {props.children}
    </ul>
  );
})

export default ScrollableList;