import { forwardRef, MutableRefObject } from "react";
import MapPointerPopup from "./MapPointerPopup";
import { StorePopupData } from "../../types/mapData";
import { Link } from "react-router-dom";
import { BACKEND_URL } from "../../utils/constants/backendContants";
import Icon from "../shared/Icon";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

type MapStorePopupProps = {
  customClass?: string;
  variant?: "red" | "white";
  ref?: MutableRefObject<HTMLDivElement | null>;
  onClick?: () => void;
  onNavigate?: (code: string) => void;
  showNavigation?: boolean;
} & StorePopupData;

const MapStorePopup = forwardRef<HTMLDivElement, MapStorePopupProps>(function (
  props,
  ref
) {
  const {
    customClass,
    variant,
    id,
    name,
    iconUrl,
    logoUrl,
    code,
    categories,
    onNavigate,
    showNavigation,
  } = props;

  const {t} = useTranslation();

  const { getLocalizedLink} = useLocalizedLinks();

  const innerPopupContent = (
    <>
      {logoUrl && (
        <div className="logo-container">
          <img className="logo" src={`${BACKEND_URL}/${logoUrl}`} />
        </div>
      )}
      {iconUrl && (
        <div className="icon-container">
          <img className="icon" src={`${iconUrl}`} />
        </div>
      )}
      {name && <div className="name">{name}</div>}
      {categories && categories.length > 0 && (
        <div className="categories">
          {categories.map((category, index) => {
            return <span key={`category-${index}`}>{category}</span>;
          })}
        </div>
      )}
    </>
  );

  return (
    <MapPointerPopup variant={variant} customClass={customClass} ref={ref}>
      {id ? (
        <Link to={getLocalizedLink("sklepy",id)}>
          {innerPopupContent}
        </Link>
      ) : (
        innerPopupContent
      )}

      {showNavigation && code && (
        <button
          className="btn btn-navigate"
          onClick={(event) => {
            event.stopPropagation();
            onNavigate?.(code);
          }}
        >
          {<Icon name={"navigate"} />}
          {t("Nawiguj")}
        </button>
      )}
    </MapPointerPopup>
  );
});

export default MapStorePopup;
