import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux/store";
import Icon from "../../../shared/Icon";
import ScrollableList from "../../../shared/ScrollableList";
import SimpleListItem from "../../../shared/SimpleListItem";
import { ContentPopupContext } from "../../ContentPopup";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

export default function News() {
  const [activeTab, setActiveTab] = useState<
    "news" | "newCollections"
  >("news");

  const {getLocalizedLink} = useLocalizedLinks();

  const news = useAppSelector((state) => {
    return state.mapState.mapData?.news;
  });

  const newCollections = useAppSelector((state) => {
    return state.mapState.mapData?.new_collections;
  });

  const scrollableListRef = useRef<HTMLUListElement | null>(null);

  const {t} = useTranslation();

  useDynamicBlocades(scrollableListRef);

  const { changePopupHeader } = useContext(ContentPopupContext);

    const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Nowości")
  },[logPageViewEvent])

  useEffect(() => {
    changePopupHeader(t("Nowości"), "icm_new");
  }, []);

  if (!news || !newCollections)
    return null;

  return (
    <div className="news-container">
      <div className="buttons-container">
        <button
          className={`tab-button ${activeTab === "news" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("news");
          }}
        >
          <Icon name="icm_aktualnosci" /> {t("Aktualności")}
        </button>
        <button
          className={`tab-button ${
            activeTab === "newCollections" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("newCollections");
          }}
        >
          <Icon name="icm_nowe-kolekcje" /> {t("Nowe kolekcje")}
        </button>
      </div>
      <ScrollableList ref={scrollableListRef}>
        {activeTab === "news" &&
          news.map((news) => {
            return (
              <SimpleListItem
                key={news.id}
                title={news.name}
                imageUrl={news.thumb}
                link={getLocalizedLink("aktualnosci", news.id)}
                topInfo={{ text: news.date_add.split(" ")[0], icon: "icm_aktualnosci" }}
              ></SimpleListItem>
            );
          })}
        {activeTab === "newCollections" &&
          newCollections.map((newCollection) => {
            return (
              <SimpleListItem
              topInfo={{text: newCollection.date_add.split(" ")[0], icon: "icm_nowe-kolekcje"}}
                key={newCollection.id}
                link={getLocalizedLink("sklepy",newCollection.store_id)}
                title={newCollection.title}
                imageUrl={newCollection.image}
                subtitle={newCollection.intro}
              ></SimpleListItem>
            );
          })}
      </ScrollableList>
    </div>
  );
}
