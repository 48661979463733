import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import SimpleDetail from "../shared/SimpleDetail";
import useHttp from "../../../../hooks/useHttp";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import { useContext, useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import { Event as EventType } from "../../../../types/mapData";
import { ContentPopupContext } from "../../ContentPopup";
import { useTranslation } from "react-i18next";

export default function Event() {
  const { eventId } = useParams();

  const [description, setDescription] = useState<string | null>(null);

  const {changePopupHeader} = useContext(ContentPopupContext)

  const {t, i18n} = useTranslation();

  const [sendRequest, isLoading] = useHttp(
    `${BACKEND_URL}${i18n.language !== "pl" ? `/${i18n.language}` :""}/${`scheme/event-detail/id/${eventId}`}`
  );

  function handleResponse(response: Response) {
    if (!response.ok) {
      throw new Error("Could not download event data");
    }
    return response.json().then((data: EventType & {description: string}) => {
      setDescription(data.description);
    });
  }

  function handleError(error: Error) {
    console.error(error);
  }

  const event = useAppSelector((state) => {
    return state.mapState.mapData?.events;
  })?.find((event) => event.id === eventId);

  useEffect(() => {
    changePopupHeader(event?.name || t("Wydarzenia"), "icm_wydarzenia")
    if (eventId) {
      sendRequest(handleResponse, handleError);
    }
  }, []);

  if (!event) return null;

  return (
    <SimpleDetail image={event.thumb} addDate={event.date_start.split(" ")[0]}>
      {!isLoading && description !== undefined && description !== null ? (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      ) : (
        <Loading />
      )}
    </SimpleDetail>
  );
}
