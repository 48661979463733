import { useContext, useEffect } from "react";
import SimpleDetail from "../shared/SimpleDetail";
import { ContentPopupContext } from "../../ContentPopup";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

export default function GiftCard() {
  const { changePopupHeader } = useContext(ContentPopupContext);

  const giftCard = useAppSelector((state) => {
    return state.mapState.mapData?.gift_card;
  });

  const { t } = useTranslation();

  useEffect(() => {
    changePopupHeader(t("Karta podarunkowa"), "icm_kart-podarunkowa");
  }, []);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Karta podarunkowa")
  },[logPageViewEvent])

  if (!giftCard) return null;

  return (
    <SimpleDetail
      image={`${giftCard.thumb}`}
      description={giftCard.description}
        customClass="gift-card"
    />
  );
}
