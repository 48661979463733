export const Urls = {
  forRouter: {
    pl: {
      mapPageRoutes: [
        "/",
        "/sklepy",
        "/sklepy/:shopId",
        "/jedzenie",
        "/promocja/:promotionId",
        "/aktualnosci",
        "/aktualnosci/:newsId",
        "/wydarzenia",
        "/wydarzenia/:eventId",
        "/karta-podarunkowa",
        "/informacja",
        "/serwisy/*",
        "/krakow",
        "/krakow/:landmarkId",
        "/parking",
        "/wifi",
        "/wynajem-wozkow",
        "/tax-free",
        "/aplikacja",
      ],
      promotionRoutes: ["/promocje", "/promocje/:promotionId"],
      screensaverRoute: "/screensaver",
      loaderRoute: "*",
      navigateToCar: "/navigate-to-car",
      standSlider: "/stand-slider",
      verticalStandSlider: "/vertical-stand-slider",
    },
    en: {
      mapPageRoutes: [
        "/en",
        "/en/shops",
        "/en/shops/:shopId",
        "/en/food",
        "/en/promotion/:promotionId",
        "/en/news",
        "/en/news/:newsId",
        "/en/events",
        "/en/events/:eventId",
        "/en/gift-card",
        "/en/information",
        "/en/services/*",
        "/en/crakow",
        "/en/crakow/:landmarkId",
        "/en/parking",
        "/en/wifi",
        "/en/pram-rental",
        "/en/tax-free",
        "/en/application",
      ],
      promotionRoutes: ["/en/promotions", "/en/promotions/:promotionId"],
      screensaverRoute: "/en/screensaver",
      navigateToCar: "/en/navigate-to-car",
    },
  },
  forLinks: {
    "/": { pl: "/", international: "" },
    sklepy: { pl: "/sklepy", international: "/shops" },
    jedzenie: { pl: "/jedzenie", international: "/food" },
    promocja: { pl: "/promocja", international: "/promotion" },
    promocje: { pl: "/promocje", international: "/promotions" },
    aktualnosci: { pl: "/aktualnosci", international: "/news" },
    wydarzenia: { pl: "/wydarzenia", international: "/events" },
    "karta-podarunkowa": {
      pl: "/karta-podarunkowa",
      international: "/gift-card",
    },
    informacja: { pl: "/informacja", international: "/information" },
    serwisy: { pl: "/serwisy", international: "/services" },
    krakow: { pl: "/krakow", international: "/crakow" },
    parking: { pl: "/parking", international: "/parking" },
    screensaver: { pl: "/screensaver", international: "/screensaver" },
    "tax-free": { pl: "/tax-free", international: "/tax-free" },
    aplikacja:{ pl: "/aplikacja", international: "/application" }
  },
};

export const KioskIdByLocationMap: {[key: string]: {id: number, name: string} | undefined} = {
  "-23.1022,9.77513,0,3.14": {
    id: 1,
    name: "Kiosk przy dworcu PKP. Poziom -1.",
  },
  "0,0,0,0": { id: 2, name: "Kiosk przy Hali Forum. Poziom -1." },
  "-23.0301,-3.20726,1,0": {
    id: 3,
    name: "Kiosk przy Hali Europejskiej. Poziom 0.",
  },
  "-3.1666,3.24499,1,1.57": {
    id: 4,
    name: "Kiosk przy punkcie informacji. Poziom 0.",
  },
  "-0.003405,-1.77759,1,0": {
    id: 5,
    name: "Kiosk przy Hali Forum. Poziom 0.",
  },
  "22.8516,0.077648,1,1.57": {
    id: 6,
    name: "Kiosk przy Hali Grodzkiej. Poziom 0",
  },
  "0.020635,3.55902,2,-3.14": {
    id: 7,
    name: "Kiosk w sekcji gastronomicznej. Poziom 1.",
  },
  "19.6031,1.07758,3,-1.57": {
    id: 8,
    name: "Kiosk przy sektorze C. Poziom 2.",
  },
  "-24.8648,5.42682,3,1.57": {
    id: 9,
    name: "Kiosk przy sektorze A. Poziom 2.",
  },
};
