import { useContext, useEffect, useState } from "react";
import ScrollableList from "../../../shared/ScrollableList";
import { ContentPopupContext } from "../../ContentPopup";
import { useTranslation } from "react-i18next";

const PARKING_MAP = [
  {
    name: "Sektor A",
    levelIndex: 3,
    color: "#ad1510",
    location: "-28.94997,2.53832,3",
  },
  {
    name: "Sektor B",
    levelIndex: 3,
    color: "#c27304",
    location: "-9.94997,2.53832,3",
  },
  {
    name: "Sektor C",
    levelIndex: 3,
    color: "#607a11",
    location: "10.94997,2.53832,3",
  },
  {
    name: "Sektor D",
    levelIndex: 4,
    color: "#5c076b",
    location: "-28.94997,2.53832,4",
  },
  {
    name: "Sektor E",
    levelIndex: 4,
    color: "#020678",
    location: "-10.94997,2.53832,4",
  },
  {
    name: "Sektor F",
    levelIndex: 4,
    color: "#038a39",
    location: "10.94997,2.53832,4",
  },
];

export default function Parking() {
  const [activeParking, setActiveParking] = useState<number | null>(null);

  const { changePopupHeader } = useContext(ContentPopupContext);

  const { t } = useTranslation();

  useEffect(() => {
    changePopupHeader(t("Parking"), "icm_parkingi");
  }, []);

  return (
    <ScrollableList customClass="parking-container">
      {PARKING_MAP.map((parking, index) => {
        return (
          <li
            key={index}
            style={{ backgroundColor: parking.color }}
            className={`parking-button ${
              activeParking === index ? "active" : ""
            }`}
            onClick={() => {
              const locationArray = parking.location.split(",");
              setActiveParking(index);
              map.moveTo(
                true,
                +locationArray[0],
                +locationArray[1],
                0,
                26,
                undefined,
                +locationArray[2]
              );
            }}
          >
            {t(parking.name)}
          </li>
        );
      })}
    </ScrollableList>
  );
}
