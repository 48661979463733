import { useContext, useEffect, useRef } from "react";
import { useAppSelector } from "../../../../redux/store";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableList from "../../../shared/ScrollableList";
import SimpleListItem from "../../../shared/SimpleListItem";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

export default function Events() {
  const events = useAppSelector((state) => {
    return state.mapState.mapData?.events;
  });
  const wifi = useAppSelector((state) => {
    return state.mapState.mapData?.wifi;
  });

  const pram = useAppSelector((state) => {
    return state.mapState.mapData?.pram;
  });

  const scrollableListRef = useRef<HTMLUListElement | null>(null);

  const { t } = useTranslation();

  const { getLocalizedLink } = useLocalizedLinks();

  useDynamicBlocades(scrollableListRef);

  const { changePopupHeader } = useContext(ContentPopupContext);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Wydarzenia")
  },[logPageViewEvent])

  useEffect(() => {
    changePopupHeader(t("Wydarzenia"), "icm_wydarzenia");
  }, []);

  if (!events) return null;

  return (
    <ScrollableList ref={scrollableListRef}>
      {events.map((event) => {
        return (
          <SimpleListItem
            title={event.name}
            topInfo={{
              text: event.date_start.split(" ")[0],
              icon: "icm_wydarzenia",
            }}
            imageUrl={event.thumb}
            key={event.id}
            link={getLocalizedLink("wydarzenia", event.id)}
          />
        );
      })}
    </ScrollableList>
  );
}
