import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useRedirectOnMove(
  timeInMs: number,
  screensaverRedirectUrl: string = "/screensaver",
  homeRedirectUrl = "/"
) {
  const timerRef = useRef<number | undefined>();

  const navigate = useNavigate();
  const location = useLocation();

  const redirectHandler = useCallback(() => {
    clearTimeout(timerRef.current);
    if (location.pathname !== screensaverRedirectUrl) {
      timerRef.current = setTimeout(
        () => {
          navigate(screensaverRedirectUrl);
        },
        timeInMs,
        undefined
      );
    } else {
      window.location.assign(homeRedirectUrl);
    }
  }, [timeInMs, screensaverRedirectUrl, homeRedirectUrl, location.pathname,navigate]);

  useEffect(() => {
    if (location.pathname !== screensaverRedirectUrl) {
      redirectHandler();
    }
    document.addEventListener("mousemove", redirectHandler);
    return () => {
      document.removeEventListener("mousemove", redirectHandler);
    };
  }, [redirectHandler, location.pathname, screensaverRedirectUrl]);
}
