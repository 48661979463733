import { forwardRef, PropsWithChildren } from "react";

type ScrollableContainerProps = {
  customClass?: string;
  fullWidth?: boolean;
} & PropsWithChildren;

const ScrollableContainer = forwardRef<HTMLDivElement,ScrollableContainerProps>(function (props,ref) {
  return (
    <div ref={ref}
      className={`scrollable-container ${props.customClass ? props.customClass :""} ${
        props.fullWidth ? "full-width" : ""
      }`}
    >
      {props.children}
    </div>
  );
})

export default ScrollableContainer;