import { useCallback, useLayoutEffect} from "react";

export default function useDocumentBlocades() {

  const blockRightClick = useCallback(function () {
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

  const blockTabClick = useCallback(function blockTabClick() {
    document.addEventListener("keydown", (event) => {
      const code = event.key;
      if (code === "Tab") {
        event.preventDefault();
        return;
      }
    });
  }, []);

  useLayoutEffect(() => {
    blockRightClick();
    blockTabClick();
  }, [blockRightClick, blockTabClick]);

}
