import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import {
  COFFEE_SHOP_CATEGORY_ID,
  RESTAURANT_CATEGORY_ID,
} from "../../../../utils/constants/backendContants";
import { isLetter } from "../../../../utils/functions/string";
import ScrollToLetters from "../shared/ScrollToLetters";
import ShopsListWithLetterSections from "../shared/ShopsListWithLetterSections";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableList from "../../../shared/ScrollableList";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

export default function Foods() {
  const foodStores = useAppSelector((state) => {
    return state.mapState.mapData?.store;
  })?.filter((store) => {
    return (
      store.category_ids?.includes(COFFEE_SHOP_CATEGORY_ID) ||
      store.category_ids?.includes(RESTAURANT_CATEGORY_ID)
    );
  });

  const {t} = useTranslation();

  const navigate = useNavigate();

  const scrollableListRef = useRef<HTMLUListElement | null>(null);
  useDynamicBlocades(scrollableListRef, true);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Restauracje i kawiarnie")
  },[logPageViewEvent])

  const { changePopupHeader } = useContext(ContentPopupContext);

  useLayoutEffect(() => {
    changePopupHeader(t("Restauracje i kawiarnie"), "icm_food");
  }, []);

  if (!foodStores) return null;

  const letters: string[] = [];

  foodStores.forEach((store) => {
    if (!isLetter(store.name[0]) && letters.indexOf("#") === -1) {
      letters.push("#");
    } else if (letters.indexOf(store.name[0].toUpperCase()) === -1) {
      letters.push(store.name[0].toUpperCase());
    }
  });

  function navigateButtonClickHandler(code: string) {
    navigate("/?navigate_to_store=" + code);
  }

  return (
    <div className="shops-container">
      <ScrollableList ref={scrollableListRef} customClass="shops-list-scrollable-container">
        <ShopsListWithLetterSections
          letters={letters}
          shops={foodStores}
          onNavigateClick={navigateButtonClickHandler}
        />
      </ScrollableList>
      <ScrollableList>
        <ScrollToLetters letters={letters} />
      </ScrollableList>
    </div>
  );
}
