import { forwardRef, useEffect, useState } from "react";
import Icon from "../shared/Icon";
import BottomBarItem from "./BottomBarItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import { Swiper as SwiperType } from "swiper/types";
import { FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

const taxFreeServiveId = "10";

const BottomBar = forwardRef<HTMLDivElement | null, {}>((props, ref) => {
  const swiperRef = useRef<SwiperType | null>(null);

  const location = useLocation();

  const {getLocalizedLink} = useLocalizedLinks();

  const { t } = useTranslation();

  const services =
    useAppSelector((state) => {
      return state.mapState.mapData?.service_category;
    }) || [];

  useEffect(() => {
    let timer = setTimeout(
      () => {
        if (swiperRef.current != null) {
          swiperRef.current.update();
        }
      },
      300,
      []
    );

    return () => {
      clearTimeout(timer);
    };
  }, [swiperRef.current, location.pathname]);

  return (
    <div className="bottom-bar" ref={ref}>
      <span className="header">
        <Icon name="icm_serwisy" /> {t("serwisy")}
      </span>
      <div className="swiper-container">
        <Swiper
          spaceBetween={16}
          slidesPerView={"auto"}
          modules={[FreeMode]}
          freeMode={{
            enabled: true,
          }}
          onSwiper={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
        >
          {services
            .filter((service) => {
              return !!service.logo;
            })
            .map((service) => {
              return (
                <SwiperSlide key={service.id}>
                  <BottomBarItem
                    imageUrl={service.logo!!}
                    label={service.title}
                    url={service.id === taxFreeServiveId ? getLocalizedLink("tax-free") : service.service_url}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
});

export default BottomBar;
