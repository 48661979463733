import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Icon from "../shared/Icon";
import { Link } from "react-router-dom";
import { createContext } from "react";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

type ContentPopupProps = {
  isVisible: boolean;
} & PropsWithChildren;

type PopupHeaderState = {
  headerLabel: string;
  icon?: string;
  imageData?: {
    imageUrl: string;
    storeId: string;
  };
};

export const ContentPopupContext = createContext<{
  changePopupHeader: (
    headerLabel: string,
    icon?: string,
    imageData?: {
      imageUrl: string;
      storeId: string;
    }
  ) => void;
}>({ changePopupHeader: () => {} });

export default function ContentPopup(props: ContentPopupProps) {
  const { isVisible } = props;

  const [popupHeader, setPopupHeader] = useState<PopupHeaderState>({
    headerLabel: "",
    icon: "",
    imageData: {
      imageUrl: "",
      storeId: "",
    },
  });

  const contentPopupRef = useRef<HTMLDivElement | null>(null);

  const { getLocalizedLink } = useLocalizedLinks();

  function changePopupHeader(
    headerLabel: string,
    icon?: string,
    imageData?: {
      imageUrl: string;
      storeId: string;
    }
  ) {
    setPopupHeader({
      headerLabel,
      icon,
      imageData,
    });
  }

  useEffect(() => {
    let timer: null | number = null;
    if (!contentPopupRef.current) return;
    if (isVisible) {
      contentPopupRef.current.classList.add("display");
      timer = setTimeout(
        () => {
          if (contentPopupRef.current) {
            contentPopupRef.current.classList.add("show");
          }
        },
        10,
        []
      );
    } else {
      contentPopupRef.current.classList.remove("show");
      timer = setTimeout(
        () => {
          if (contentPopupRef.current) {
            contentPopupRef.current.classList.remove("display");
          }
        },
        200,
        []
      );
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isVisible]);

  return (
    <ContentPopupContext.Provider value={{ changePopupHeader }}>
      <div className="content-popup" ref={contentPopupRef}>
        <div className="popup-header-container">
          <span className="popup-header">
            {!popupHeader.imageData &&
              popupHeader.icon &&
              popupHeader.icon.length > 0 && <Icon name={popupHeader.icon} />}
            {popupHeader.imageData && (
              <Link to={getLocalizedLink("sklepy",popupHeader.imageData.storeId)}>
                <img src={popupHeader.imageData.imageUrl} alt="popup-header" />
              </Link>
            )}
            {popupHeader.headerLabel}
          </span>
          <Link to={getLocalizedLink("/")} className="popup-header-close-btn">
            <Icon name="close" />
          </Link>
        </div>
        <div className="popup-body">{props.children}</div>
      </div>
    </ContentPopupContext.Provider>
  );
}
