import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { layoutActions } from "../redux/layoutSlice";
import { useTranslation } from "react-i18next";

export default function usePageBlocades(pageRefs: React.MutableRefObject<HTMLElement | null>[]) {

    const browserHost = window.location.host;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const didAddListeners = useRef(false);
  
    const blockInputsStandardKeyboard = useCallback(function () {
      pageRefs.forEach((ref)=>{
        if(ref.current === null) return;
        ref.current.querySelectorAll("input").forEach((item) => {
          item.setAttribute("inputmode", "none");
        })
      })
      }, [pageRefs]);

    const blockLinkHandler = useCallback(
        (event: MouseEvent) => {
          event.preventDefault();
          const anchorTagElement = event.currentTarget as HTMLAnchorElement | null;
          if (anchorTagElement === null) return;
          const url = new URL(anchorTagElement.href);
          if (url.host === browserHost) {
            navigate(url.pathname + url.search);
          } else {
            dispatch(layoutActions.showSnackbar(t("Funkcja niedostępna w kiosku")));
            return;
          }
        },
        [navigate, browserHost,dispatch,t]
      );

      const blockExternalLinks = useCallback(
        function () {
            pageRefs.forEach((pageRef)=>{
              if(pageRef.current === null) return;
              pageRef.current.querySelectorAll("a").forEach((item) => {
                item.addEventListener("click", blockLinkHandler);
              });
            })
          didAddListeners.current = true;
        },
        [blockLinkHandler,pageRefs]
      );

      useEffect(()=>{
        if(didAddListeners.current) return;
        blockExternalLinks();
        blockInputsStandardKeyboard();
      },[blockExternalLinks,blockInputsStandardKeyboard])
}