import { createSlice } from "@reduxjs/toolkit";

type keyboardSliceState = {
    visible: boolean;
}


const initialState: keyboardSliceState = {
    visible: false,
}

const keyboardSlice = createSlice({
    name: "keyboard",
    initialState: initialState,
    reducers: {
        showKeyboard: (state) => {
            state.visible = true;
        },
        hideKeyboard: (state) => {
            state.visible = false;
        },
    },
})

export const keyboardActions = keyboardSlice.actions;
export default keyboardSlice.reducer;