import { useLocation, useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

export default function ReturnButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation();
  
  const {getLocalizedLink} = useLocalizedLinks();

    if (location.pathname === getLocalizedLink("/") && location.search === "") {
        return null;
    }

  return (
    <Button onClick={() => navigate(-1)} variant="primary" icon="chevron-left">
      {t("wstecz")}
    </Button>
  );
}
