import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { BACKEND_URL } from "../utils/constants/backendContants";
import PageOnMapContainer from "../components/shared/PageOnMapContainer";
import usePageBlocades from "../hooks/usePageBlocades";
import { useEffect, useRef } from "react";
import { layoutActions } from "../redux/layoutSlice";
import useLocalizedLinks from "../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../hooks/useLogPageViewEvent";

export default function PromotionsPage() {
  const pageContainerRef = useRef<HTMLDivElement | null>(null);

  usePageBlocades([pageContainerRef]);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Promocje")
  },[logPageViewEvent])

  const { getLocalizedLink } = useLocalizedLinks();

  const mapData = useAppSelector((state) => {
    return state.mapState.mapData;
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layoutActions.closeSideMenu());
  }, []);

  if (!mapData) return null;

  const promotions = mapData.promotions;

  const firstColumnData = promotions.filter((promotion, index) => {
    return index % 4 === 0;
  });
  const secondColumnData = promotions.filter((promotion, index) => {
    return index % 4 === 1;
  });
  const thirdColumnData = promotions.filter((promotion, index) => {
    return index % 4 === 2;
  });
  const fourthColumnData = promotions.filter((promotion, index) => {
    return index % 4 === 3;
  });

  return (
    <PageOnMapContainer ref={pageContainerRef}>
      <div className="promotions-container">
        <div className="column-1">
          {firstColumnData.map((promotion) => {
            return (
              <Link
                to={getLocalizedLink("promocje", promotion.id)}
                key={promotion.id}
                className="promotion"
              >
                <img
                  src={`${BACKEND_URL}/${promotion.image}`}
                  alt={promotion.title}
                />
                <span className="promotion-title">{promotion.title}</span>
              </Link>
            );
          })}
        </div>
        <div className="column-2">
          {secondColumnData.map((promotion) => {
            return (
              <Link
                to={getLocalizedLink("promocje", promotion.id)}
                key={promotion.id}
                className="promotion"
              >
                <img
                  src={`${BACKEND_URL}/${promotion.image}`}
                  alt={promotion.title}
                />
                <div className="promotion-title">{promotion.title}</div>
              </Link>
            );
          })}
        </div>
        <div className="column-3">
          {thirdColumnData.map((promotion) => {
            return (
              <Link
                to={getLocalizedLink("promocje", promotion.id)}
                key={promotion.id}
                className="promotion"
              >
                <img
                  src={`${BACKEND_URL}/${promotion.image}`}
                  alt={promotion.title}
                />
                <div className="promotion-title">{promotion.title}</div>
              </Link>
            );
          })}
        </div>
        <div className="column-4">
          {fourthColumnData.map((promotion) => {
            return (
              <Link
                to={getLocalizedLink("promocje", promotion.id)}
                key={promotion.id}
                className="promotion"
              >
                <img
                  src={`${BACKEND_URL}/${promotion.image}`}
                  alt={promotion.title}
                />
                <div className="promotion-title">{promotion.title}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </PageOnMapContainer>
  );
}
