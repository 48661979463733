import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import mapReducer from "./mapSlice";
import keyboardReducer from "./keyboardSlice";
import layoutReducer from "./layoutSlice";

export const store = configureStore({
  reducer: {
    mapState: mapReducer,
    keyboardState: keyboardReducer,
    layoutState: layoutReducer,
  },
});

type AppState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<AppState>();
