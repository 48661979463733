import { useContext, useEffect } from "react";
import SimpleDetail from "../shared/SimpleDetail";
import { ContentPopupContext } from "../../ContentPopup";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";

export default function Wifi() {
  const { changePopupHeader } = useContext(ContentPopupContext);

  const wifi = useAppSelector((state) => {
    return state.mapState.mapData?.wifi;
  });

  const { t } = useTranslation();

  useEffect(() => {
    changePopupHeader(t("Darmowe WiFi"), "WiFi");
  }, []);

  if (!wifi) return null;

  return (
    <SimpleDetail
      image={wifi.image || undefined}
      addDate={wifi.date_add.split(" ")[0]}
      description={wifi.content}
        customClass="wifi"
    />
  );
}
