import { useCallback, useMemo } from "react";
import useLogEvent from "./useLogEvent";
import { useAppSelector } from "../redux/store";
import { KioskIdByLocationMap } from "../utils/constants/frontendConstants";

export default function useLogPageViewEvent() {
  const logEvent = useLogEvent("page_view");

  const kiosk_location = useAppSelector((state) => {
    return state.mapState.kioskLocation;
  });

  return useCallback((pageName: string, otherParams?:{
    storeName?: string;
    serviceName?: string;
  }) => {
    if (!kiosk_location) return;
    const params: { [key: string]: string | number } = {};
    const kioskIdObject =
      KioskIdByLocationMap[kiosk_location] || KioskIdByLocationMap["0,0,0,0"];
    if (kioskIdObject) {
        params["kiosk_id"] = kioskIdObject.id;
        params["kiosk_name"] = kioskIdObject.name;
    }
    params["page_name"] = pageName;
    if(otherParams?.storeName){
      params["store_name"] = otherParams.storeName
    }
    if(otherParams?.serviceName){
      params["service_name"] = otherParams.serviceName
    }
    logEvent(params);
  }, [logEvent, kiosk_location]);
}
