import { Outlet } from "react-router-dom";
import { MapData } from "../../types/mapData";
import Map from "./Map";
import { useCallback, useEffect, useRef, useState } from "react";
import NavigationPanel from "./NavigationPanel";
import { mapActions } from "../../redux/mapSlice";
import { useAppDispatch } from "../../redux/store";
import MapPointerPopup from "./MapPointerPopup";
import Icon from "../shared/Icon";
import { useTranslation } from "react-i18next";

type NavigateToCarMapProps = {
  mapData?: MapData;
  onMapLoaded?: () => void;
};

export default function NavigateToCarMap(props: NavigateToCarMapProps) {
  const { mapData, onMapLoaded } = props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [navigationHelperPopupText, setNavigationHelperPopupText] =
    useState<string>("");

  const navigationHelperPopupRef = useRef<HTMLDivElement | null>(null);

  const initHandlers = useCallback(() => {
    map.onLevelDidChanged = (level) => {};
    map.onLevelWillChange = (currentIndex, newIndex) => {
      dispatch(mapActions.setActiveLevel(newIndex - 1));
    };
    map.onNavigationHelperPopupHide = () => {
      if (navigationHelperPopupRef.current) {
        navigationHelperPopupRef.current.classList.remove("show");
      }
    };
    map.onNavigationHelperPopupShow = () => {
      if (navigationHelperPopupRef.current) {
        navigationHelperPopupRef.current.classList.add("show");
      }
    };
    map.onNavigationHelperPopupPositionChanged = (x: number, y: number) => {
      if (navigationHelperPopupRef.current) {
        navigationHelperPopupRef.current.style.left = `${x}px`;
        navigationHelperPopupRef.current.style.top = `${y}px`;
      }
    };
    map.onNavigationHelperPopupTextChanged = (text: string) => {
      setNavigationHelperPopupText(text);
    };
  }, []);

  useEffect(() => {
    initHandlers();
  }, [initHandlers]);

  return (
    <>
      <div className="popup-layer navigate-to-car-map-paddings">
        <Map mapData={mapData} onMapLoaded={onMapLoaded} initialFloor={3} navigateToCarMode={true} />
        <MapPointerPopup
          ref={navigationHelperPopupRef}
          onClick={() => {
            map.changeLevelToNavigationNextStep();
          }}
          customClass="navigation-helper-popup"
        >
          {t(navigationHelperPopupText)} <Icon name={"chevron-right"} />
        </MapPointerPopup>
      </div>
      <Outlet />
    </>
  );
}
