import { ReactElement, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Popup from "../components/shared/Popup";
import { useTranslation } from "react-i18next";
import Button from "../components/shared/Button";
import useHttp from "../hooks/useHttp";
import { BACKEND_URL } from "../utils/constants/backendContants";
import { useAppDispatch, useAppSelector } from "../redux/store";
import getSingleCookie from "../utils/functions/getSingleCookie";
import setSingleCookie from "../utils/functions/setSingleCookie";
import { layoutActions } from "../redux/layoutSlice";
import { mapActions } from "../redux/mapSlice";
import NavigationPanel from "../components/map/NavigationPanel";
import LanguageSwitch from "../components/topBar/LanguageSwitch";
import Snackbar from "../components/shared/Snackbar";

type LocationResponse = {
  level: number;
  type: number;
  code: number;
  x: number;
  y: number;
  z: number;
  r: number;
  sector: string;
};

const DOOR_TYPE = 1;
const CAR_TYPE = 0;

export default function NavigateToCarPage() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [isNavigationPanelVisible, setNavigationPanelVisible] = useState(false);

  const [popupContetId, setPopupContentId] = useState<
    "initial" | "car-first" | "door-first" | "second-car" | null
  >(null);

  const navigateToLocationRef = useRef<LocationResponse | undefined>();
  const navigateFromLocationRef = useRef<LocationResponse | undefined>();

  const dispatch = useAppDispatch();

  const activeLevel = useAppSelector((state) => state.mapState.activeLevel);

  const { t } = useTranslation();

  const [query] = useSearchParams();

  const code = query.get("code");

  const [sendGetScannedLocationRequest, isLocationDataLoading] = useHttp(
    `${BACKEND_URL}/scheme/qr-codes`
  );

  function updateCarNode() {
    const toLocation = navigateToLocationRef.current
    if (!toLocation) {
      return;
    }
    map.updateCarNode(toLocation.x, toLocation.y, toLocation.z, toLocation.level)
  }

  function overrideCarLocation() {
    if (!navigateFromLocationRef.current) {
      return;
    }
    setSingleCookie(
      "car_location",
      JSON.stringify(navigateFromLocationRef.current),
      new Date(Date.now() + 1000 * 60 * 60 * 24)
    );
    navigateToLocationRef.current = navigateFromLocationRef.current;
    navigateFromLocationRef.current = undefined;
    updateCarNode();
  }

  function startNavigation(
    fromLocation?: LocationResponse,
    toLocation?: LocationResponse,
    wheelchairMode?: boolean
  ) {
    if (!fromLocation || !toLocation) {
      return;
    }
    map.navigateToPoint(
        toLocation.x,
        toLocation.y,
        toLocation.level,
        fromLocation.x,
        fromLocation.y,
        fromLocation.level,
        wheelchairMode || false,
        true,
        false,
        true
    );
  }

  function handleLocationResponse(response: Response) {
    if (!response.ok) {
      throw new Error("Nie udało się pobrać danych");
    }
    return response.json().then((data: LocationResponse) => {
      if (!navigateToLocationRef.current && data.type === DOOR_TYPE) {
        setPopupContentId("door-first");
      } else if (!navigateToLocationRef.current) {
        setPopupContentId("car-first");
        navigateToLocationRef.current = data;
        map.moveTo(true, data.x, data.y, data.z, 40, undefined, data.level);
        updateCarNode();
        setSingleCookie(
          "car_location",
          JSON.stringify(data),
          new Date(Date.now() + 1000 * 60 * 60 * 24)
        );
      } else if (navigateToLocationRef.current && data.type === CAR_TYPE) {
        setPopupContentId("second-car");
        navigateFromLocationRef.current = data;
      } else {
        startNavigation(navigateToLocationRef.current, data);
      }
    });
  }

  function handleLocationError(error: Error) {
    dispatch(layoutActions.showSnackbar(t(error.message)));
  }

  const popupContentRef = useRef<{
    header?: string;
    content: ReactElement;
  } | null>(null);

  let content: ReactElement | null = null;
  switch (popupContetId) {
    case "initial":
      content = (
        <div className="navigation-message">
          <p>{`${t(
            "Aby rozpocząć zeskanuj kod QR znajdujący na ścianach się w pobliżu twojego auta"
          )}.`}</p>
        </div>
      );
      popupContentRef.current = {
        header: t("Skorzystaj z nawigacji"),
        content: content,
      };
      break;
    case "car-first":
      content = (
        <div className="navigation-message">
          <p>
            <b>{`${t(
              "Lokalizacja twojego pojazdu została zapisana w sektorze {{sectorLetter}} na piętrze {{levelNumber}}",
              {
                sectorLetter: navigateToLocationRef.current?.sector,
                levelNumber: navigateToLocationRef.current
                  ? navigateToLocationRef.current.level - 1
                  : "",
              }
            )}.`}</b>
          </p>
          <p>{`${t(
            "Aby móc później nawigować do pojazdu należy zeskanować kod QR znajdujący się przy wejściach na parking lub kod QR przy dowolnym pojeździe"
          )}.`}</p>
          <p>{`${t(
            "Od miejsca w którym zostanie zeskanowany kod rozpocznie się nawigacja"
          )}.`}</p>
        </div>
      );
      popupContentRef.current = {
        header: t("Kolejne kroki"),
        content: content,
      };
      break;
    case "door-first":
      content = (
        <div className="navigation-message">
          <p>{`${t(
            "Aby nawigacja działała poprawnie jako pierwszy należy zeskanować kod QR znajdujący się przy pojeździe"
          )}.`}</p>
          <p>{`${t("Spróbuj ponownie")}.`}</p>
        </div>
      );
      popupContentRef.current = {
        header: t("Niepoprawna kolejność skanowania"),
        content: content,
      };
      break;
    case "second-car":
      content = (
        <div className="navigation-message">
          <p>{`${t(
            "Chcesz rozpocząć nawigację ze wskazanego miejsca, czy nadpisać poprzednią lokalizację pojazdu"
          )}?`}</p>
          <div className="buttons-container">
            <Button
              onClick={() => {
                startNavigation(
                  navigateToLocationRef.current,
                  navigateFromLocationRef.current
                );
                setPopupVisible(false);
              }}
              variant="primary"
            >
              {t("Nawiguj z tego miejsca")}
            </Button>
            <Button
              onClick={() => {
                overrideCarLocation();
                setPopupVisible(false);
                if (navigateToLocationRef.current) {
                  map.moveTo(
                      true,
                      navigateToLocationRef.current.x,
                      navigateToLocationRef.current.y,
                      navigateToLocationRef.current.z,
                      40,
                      undefined,
                      navigateToLocationRef.current.level
                  );
                }
              }}
              variant="secondary"
            >
              {t("Nadpisz lokalizację pojazdu")}
            </Button>
          </div>
        </div>
      );
      popupContentRef.current = {
        header: t("Zeskanowano kod nowego pojazdu"),
        content: content,
      };
      break;
    default:
      popupContentRef.current = null;
      break;
  }

  useEffect(() => {
    map.onNavigationStopped = () => {
      setNavigationPanelVisible(false);
    };
    map.onNavigationStarted = () => {
      setNavigationPanelVisible(true);
    };
    map.onRouteNotFound = () => {
      dispatch(layoutActions.showSnackbar(t("Nie znaleziono trasy")));
    };
  }, []);

  useEffect(() => {
    const carLocationString = getSingleCookie("car_location");
    navigateToLocationRef.current = carLocationString
        ? JSON.parse(carLocationString)
        : undefined;
    updateCarNode();
    if (!code) {
      setPopupContentId("initial");
      return;
    }
    sendGetScannedLocationRequest(
      handleLocationResponse,
      handleLocationError,
      {},
      `?code=${code}`
    );
  }, [code]);

  useEffect(() => {
    if (popupContetId === null) {
      return;
    }
    setPopupVisible(true);
  }, [popupContetId]);

  return (
    <>
      <div className="navigate-level-change-menu">
        <LanguageSwitch
          cssClass="navigation-language-switch"
          onLanguageImageClick={(lang) => {
            window.location.assign(
              `${lang.value}/navigate-to-car${window.location.search}`
            );
          }}
        />
        <button
          className={`${activeLevel === 2 ? "active" : ""}`}
          onClick={() => {
            map.moveToLevelIndex(3, true);
            dispatch(mapActions.setActiveLevel(2));
          }}
        >
          2
        </button>
        <button
          className={`${activeLevel === 3 ? "active" : ""}`}
          onClick={() => {
            map.moveToLevelIndex(4, true);
            dispatch(mapActions.setActiveLevel(3));
          }}
        >
          3
        </button>
      </div>
      <NavigationPanel
        moveUp={true}
        isVisible={isNavigationPanelVisible}
        onCloseNavigation={() => {
          map.closeNavigation();
        }}
        storeName={`${t("pojazdu w sektorze")} ${
          navigateFromLocationRef.current?.sector
        }`}
        onChangeToWheelchairMode={(wheelchairMode) => {
          startNavigation(
            navigateToLocationRef.current,
            navigateFromLocationRef.current,
            wheelchairMode
          );
        }}
      />
      <Snackbar position="top"/>
      <Popup
        header={popupContentRef.current?.header}
        onClose={() => {
          setPopupVisible(false);
        }}
        isVisible={popupVisible && popupContentRef.current?.content !== null}
      >
        {popupContentRef.current?.content}
      </Popup>
    </>
  );
}
