import { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BACKEND_URL } from "../../utils/constants/backendContants";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

type BottomBarItemProps = {
  imageUrl: string;
  label: string;
  url: string;
};

export default function BottomBarItem(props: BottomBarItemProps) {
  const { imageUrl, label, url } = props;

  const bottomBarItemInnerRef = useRef<HTMLDivElement | null>(null);
  const bottomBarItemOuterRef = useRef<HTMLAnchorElement | null>(null);

  const location = useLocation();

  const isActive = url === location.pathname;

  const {getLocalizedLink} = useLocalizedLinks();

  useEffect(() => {
    if (
      isActive &&
      bottomBarItemInnerRef.current &&
      bottomBarItemOuterRef.current
    ) {
      bottomBarItemOuterRef.current.style.width = `${
        bottomBarItemInnerRef.current.getBoundingClientRect().width
      }px`;
    }
    return () => {
      if (isActive && bottomBarItemOuterRef.current) {
        bottomBarItemOuterRef.current.style.width = "";
      }
    };
  }, [
    bottomBarItemInnerRef.current,
    bottomBarItemOuterRef.current,
    isActive
  ]);

  return (
    <NavLink
      className={`bottom-bar-item ${isActive ? "active" : ""}`}
      ref={bottomBarItemOuterRef}
      to={!isActive ? url : getLocalizedLink("/")}
    >
      <div className="inner-container" ref={bottomBarItemInnerRef}>
        <img src={`${BACKEND_URL}/${imageUrl}`} alt="" />
        <span className="label">{label}</span>
      </div>
    </NavLink>
  );
}
