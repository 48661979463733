import { Link } from "react-router-dom";
import { BACKEND_URL } from "../../utils/constants/backendContants";
import NoPhoto from "../../assets/images/utility/no-photo.png";
import Badge from "./Badge";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

type ShopListItemProps = {
  id: string;
  name: string;
  subtitle?: string;
  logo?: string;
  customClass?: string;
  hasPromotions?: boolean;
  level: string;
  code: string;
  topInfo?: { text: string; icon: string };
  onNavigateClick?: (code: string) => void;
};

export default function ShopListItem(props: ShopListItemProps) {
  const {
    id,
    name,
    subtitle,
    logo,
    hasPromotions,
    level,
    code,
    onNavigateClick,
    topInfo,
    customClass
  } = props;

  const {t} = useTranslation();

  const { getLocalizedLink } = useLocalizedLinks();

  return (
    <li className={`shop-list-item ${customClass}`} key={id}>
      <Link to={getLocalizedLink("sklepy",id)}>
        <div className="store-level">
          {level}
          <span>{t("poziom")}</span>
        </div>
        <div className="store-logo">
          <img
            loading="lazy"
            src={logo ? `${BACKEND_URL}/${logo}` : NoPhoto}
            alt={name}
          />
        </div>
        <div className="store-data">
          {topInfo && (
            <div className="top-info">
              {topInfo.icon && <Icon name={topInfo.icon} />}
              <span>{topInfo.text}</span>
            </div>
          )}
          {hasPromotions && !topInfo && (
            <Badge icon="icm_promocje">{t("Promocje")}</Badge>
          )}
          <span className="name">{name}</span>
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
        {onNavigateClick && <button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onNavigateClick(code);
          }}
        >
          <Icon name="navigate" />
          {t("Nawiguj")}
        </button>}
      </Link>
    </li>
  );
}
