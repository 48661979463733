import { useEffect, useRef } from "react";
import { useAppSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../redux/layoutSlice";

type SnackbarProps = {
  position?: "top" | "bottom";
}

export default function Snackbar(props: SnackbarProps) {

  const { position } = props;

  const snackbar = useAppSelector((state) => {
    return state.layoutState.snackbar;
  });

  const dispatch = useDispatch();

  const snackbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timer: null | number = null;
    if (!snackbarRef.current) return;
    if (snackbar.visible) {
      snackbarRef.current.classList.add("display");
      timer = setTimeout(
        () => {
          if (snackbarRef.current) {
            snackbarRef.current.classList.add("show");
          }
          timer = setTimeout(
            () => {
              dispatch(layoutActions.hideSnackbar());
            },
            7000,
            []
          );
        },
        10,
        []
      );
    } else {
      snackbarRef.current.classList.remove("show");
      timer = setTimeout(
        () => {
          if (snackbarRef.current) {
            snackbarRef.current.classList.remove("display");
            dispatch(layoutActions.clearMessage());
          }
        },
        200,
        []
      );
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [snackbar.visible, snackbar.message, dispatch]);

  return (
    <div className={`snackbar ${position && position !== "bottom" ? position : ""}`} ref={snackbarRef}>
      {snackbar.message}
    </div>
  );
}
