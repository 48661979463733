import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import englishTranslation from "./en/translation.json";
import polishTranslation from "./pl/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: englishTranslation,
    pl: polishTranslation,
  },
  lng: "pl",
  fallbackLng: "pl",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;