import { PropsWithChildren, useRef } from "react";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import ScrollableContainer from "../../../shared/ScrollableContainer";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";

type SimpleDetailProps = {
    image?: string;
    title?: string;
    description?: string;
    addDate?: string 
    params?: {name: string, value: string, icon?: string}[];
    customClass?: string;
} & PropsWithChildren

export default function SimpleDetail(props: SimpleDetailProps) {

    const {title, description, params,image,addDate, children, customClass} = props;

    const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

    useDynamicBlocades(scrollableContainerRef);

    return <ScrollableContainer ref={scrollableContainerRef} customClass={`simple-detail ${customClass ? customClass : ""}`}>
        <div className="add-date">{addDate}</div>
        { title && <h2 className="title">{title}</h2>}
        {image &&<div className="image-container">
            <img src={`${BACKEND_URL}/${image}`} alt={title} />
        </div>}
        {params &&<ul className="params-list">
            {params.map((param, index) => {
                return <li className="param" key={index}>
                    <span className="icon">{param.icon}</span>
                    <span className="name">{param.name}</span>
                    <span className="value">{param.value}</span>
                </li>
            })}
        </ul>}
        {children}
        {description && <div className="description" dangerouslySetInnerHTML={{__html:description}}></div>}
    </ScrollableContainer>
}