import { useEffect } from "react";
import { MapData } from "../../types/mapData";
import { useAppDispatch } from "../../redux/store";
import { mapActions } from "../../redux/mapSlice";

type MapProps = {
    mapData?: MapData;
    kiosk_location?: string;
    onMapLoaded?: () => void;
    initialFloor?: number;
    navigateToCarMode?: boolean;
}

export default function Map(props: MapProps) {

    const { mapData, kiosk_location, onMapLoaded, initialFloor, navigateToCarMode } = props;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!mapData) {
          return;
        }
        initMap(
            document.getElementById("map"),
            {
              mapData: mapData,
              userLocation: kiosk_location,
              defaultInitialFloor: initialFloor,
            },
            () => {
              dispatch(mapActions.setMapLoaded());
              onMapLoaded?.();
            },
            navigateToCarMode
        );
      }, [mapData, kiosk_location,initialFloor, dispatch]);

    return<div id="map"></div>
}