import { useCallback, useEffect, useRef } from "react";
import useLogEvent from "./useLogEvent";
import { useAppSelector } from "../redux/store";
import { KioskIdByLocationMap } from "../utils/constants/frontendConstants";

export default function useLogNewUserEvent() {
  const timerRef = useRef<number | undefined>();
  const logEvent = useLogEvent("new_user");

  const kiosk_location = useAppSelector((state) => {
    return state.mapState.kioskLocation;
  });

  const logUserHandler = useCallback(() => {
    clearTimeout(timerRef.current);

    if (!kiosk_location) return;
    const params: { [key: string]: string | number } = {};
    const kioskIdObject =
      KioskIdByLocationMap[kiosk_location] || KioskIdByLocationMap["0,0,0,0"];
    if (kioskIdObject) {
        params["kiosk_id"] = kioskIdObject.id;
        params["kiosk_name"] = kioskIdObject.name;
    }

    timerRef.current = setTimeout(
      () => {
        logEvent(params);
      },
      10000,
      undefined
    );
  }, [logEvent,kiosk_location]);

  useEffect(() => {
    logUserHandler()
    document.addEventListener("mousemove",logUserHandler);
    return ()=>{
        clearTimeout(timerRef.current);
        document.removeEventListener("mousemove",logUserHandler);
    }
  }, [logEvent]);
}
