import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { useAppSelector } from "../../../../redux/store";
import { isLetter } from "../../../../utils/functions/string";
import ScrollableList from "../../../shared/ScrollableList";
import ScrollToLetters from "../shared/ScrollToLetters";
import ShopsListWithLetterSections from "../shared/ShopsListWithLetterSections";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { ContentPopupContext } from "../../ContentPopup";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";


export default function TaxFree() {
    const taxFreeStores = useAppSelector((state) => {
      return state.mapState.mapData?.tax_free;
    })
  
    const {t} = useTranslation();
  
    const navigate = useNavigate();
  
    const scrollableListRef = useRef<HTMLUListElement | null>(null);
    useDynamicBlocades(scrollableListRef, true);
  
    const { changePopupHeader } = useContext(ContentPopupContext);
  
    useLayoutEffect(() => {
      changePopupHeader(t("Sklepy Tax Free"), "icm_zakupy");
    }, []);

    const logPageViewEvent = useLogPageViewEvent();

    useEffect(()=>{
      logPageViewEvent("Serwisy", {serviceName: "Tax Free"})
    },[logPageViewEvent])
  
    if (!taxFreeStores) return null;
  
    const letters: string[] = [];
  
    taxFreeStores.forEach((store) => {
      if (!isLetter(store.name[0]) && letters.indexOf("#") === -1) {
        letters.push("#");
      } else if (letters.indexOf(store.name[0].toUpperCase()) === -1) {
        letters.push(store.name[0].toUpperCase());
      }
    });
  
    function navigateButtonClickHandler(code: string) {
      navigate("/?navigate_to_store=" + code);
    }
  
    return (
      <div className="shops-container">
        <ScrollableList ref={scrollableListRef} customClass="shops-list-scrollable-container">
          <ShopsListWithLetterSections
            letters={letters}
            shops={taxFreeStores}
            onNavigateClick={navigateButtonClickHandler}
          />
        </ScrollableList>
        <ScrollableList>
          <ScrollToLetters letters={letters} />
        </ScrollableList>
      </div>
    );
  }