import { useEffect, useRef, useState } from "react";
import Button from "../shared/Button";
import { useTranslation } from "react-i18next";

type NavigationPanelProps = {
  isVisible: boolean;
  storeName?: string;
  moveUp?: boolean;
  onCloseNavigation: () => void;
  onChangeToWheelchairMode?: (wheelchairMode: boolean) => void;
};

export default function NavigationPanel(props: NavigationPanelProps) {
  const { onCloseNavigation, onChangeToWheelchairMode, isVisible, storeName,moveUp } =
    props;
  const [wheelchairMode, setWheelchairMode] = useState(false);
  const navigationPanelRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setWheelchairMode(false);
    let timer: number;
    if (!navigationPanelRef.current) return;

    if (isVisible) {
      navigationPanelRef.current.classList.add("display");
      timer = setTimeout(
        () => {
          navigationPanelRef.current?.classList.add("show");
        },
        10,
        []
      );
    } else {
      navigationPanelRef.current.classList.remove("show");
      timer = setTimeout(
        () => {
          navigationPanelRef.current?.classList.remove("display");
        },
        250,
        []
      );
    }
    return () => clearTimeout(timer);
  }, [isVisible, navigationPanelRef.current]);

  return (
    <div className={`navigation-panel ${moveUp ? "move-up" : ""}`} ref={navigationPanelRef}>
      <div className="header">
        {storeName && storeName.trim().length > 0
          ? t("Nawigacja do", { destination: storeName })
          : t("Nawigacja")}
      </div>
      <div className="buttons-container">
        {onChangeToWheelchairMode && (
          <Button
            variant="primary"
            outlined={!wheelchairMode}
            icon="wheelchair-rental"
            onClick={() => {
              setWheelchairMode((prevState) => {
                onChangeToWheelchairMode(!prevState);
                return !prevState;
              });
            }}
          >
            {t("niepełnosprawni")}
          </Button>
        )}
        <Button
          variant="secondary"
          customClass="close"
          icon="close"
          onClick={() => onCloseNavigation()}
        >
          {t("zakończ")}
        </Button>
      </div>
    </div>
  );
}
