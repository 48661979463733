import { NavLink, Outlet, useLocation } from "react-router-dom";
import Icon from "../shared/Icon";
import { useState } from "react";
import MenuSidebarAppPromo from "./MenuSidebarAppPromo";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { layoutActions } from "../../redux/layoutSlice";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

export default function MenuSidebar() {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { getLocalizedLink } = useLocalizedLinks();

  const isExpanded = useAppSelector((state) => {
    return state.layoutState.sideMenu.expanded;
  });

  return (
    <>
      <div className={`menu-sidebar ${isExpanded ? "expanded" : ""}`}>
        <button
          className={"toggle-side-menu-button"}
          onClick={() => {
            dispatch(layoutActions.toggleSideMenuExpansion());
          }}
        >
          <Icon name="chevron-left" />
        </button>
        <NavLink to={getLocalizedLink("/")} end className={"menu-sidebar-item"}>
          {t("Plan galerii")} <Icon name="icm_plan" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("sklepy")}
          className={({ isActive }) =>
            `menu-sidebar-item ${
              (isActive ||
                location.pathname === getLocalizedLink("tax-free")) &&
              location.pathname !== getLocalizedLink("sklepy", 193)
                ? "active"
                : ""
            }`
          }
        >
          {t("Sklepy")} <Icon name="icm_zakupy" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("jedzenie")}
          className={"menu-sidebar-item"}
        >
          {t("Restauracje i kawiarnie")} <Icon name="icm_food" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("promocje")}
          className={"menu-sidebar-item"}
        >
          {t("Promocje")} <Icon name="icm_promocje" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("aktualnosci")}
          className={"menu-sidebar-item"}
        >
          {t("Aktualności")} <Icon name="icm_new" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("wydarzenia")}
          className={"menu-sidebar-item"}
        >
          {t("Wydarzenia")} <Icon name="icm_wydarzenia" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("karta-podarunkowa")}
          className={"menu-sidebar-item"}
        >
          {t("Karta podarunkowa")} <Icon name="icm_kart-podarunkowa" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("sklepy", 193)}
          className={"menu-sidebar-item"}
        >
          {t("Informacja")} <Icon name="icm_informacja" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("serwisy")}
          className={"menu-sidebar-item"}
        >
          {t("Serwisy")} <Icon name="icm_serwisy" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("krakow")}
          className={"menu-sidebar-item"}
        >
          {t("Kraków")} <Icon name="icm_krakow" />
        </NavLink>
        <NavLink
          to={getLocalizedLink("parking")}
          className={"menu-sidebar-item"}
        >
          {t("Parking")} <Icon name="icm_parkingi" />
        </NavLink>
        <MenuSidebarAppPromo isMenuExpanded={isExpanded} />
      </div>
      <Outlet />
    </>
  );
}
