import { getAnalytics, logEvent } from "firebase/analytics";
import { useCallback } from "react";
import { analytics } from "../utils/constants/configConstants";

export default function useLogEvent(eventName: string) {
  return useCallback(
    (customParams?: { [key: string]: any }) => {
      logEvent(analytics, eventName, customParams);
    },
    [getAnalytics, logEvent, eventName]
  );
}
