import { forwardRef, MutableRefObject, PropsWithChildren } from "react";
import RedPointerIcon from "../../assets/images/utility/red-pointer.svg";
import WhitePointerIcon from "../../assets/images/utility/white-pointer.svg";
import useDynamicBlocades from "../../hooks/useDynamicBlocades";

type MapPointerPopupProps = {
  customClass?: string;
  variant?: "red" | "white";
  onClick?: () => void;
} & PropsWithChildren;

const MapPointerPopup = forwardRef<HTMLDivElement, MapPointerPopupProps>(
  function (props, ref) {
    const { customClass, children, variant, onClick } = props;

    return (
      <div
        className={`map-popup ${customClass ? customClass : ""} ${
          variant ? variant : "red"
        }`}
        ref={ref}
        onClick={onClick}
      >
        {children}
        <img
          className="pin"
          src={
            !variant || variant === "red" ? RedPointerIcon : WhitePointerIcon
          }
          alt="red-pointer"
        />
      </div>
    );
  }
);

export default MapPointerPopup;
