type loadingProps = {
  coverParent?: boolean;
};

export default function Loading(props: loadingProps) {
  const { coverParent } = props;
  return (
    <div className={`loader-container ${coverParent ? "cover-parent" : ""}`}>
      <div className={`loader`}></div>
    </div>
  );
}
