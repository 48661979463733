import { MouseEventHandler, PropsWithChildren } from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";

type ButtonProps = {
  variant: "primary" | "secondary";
  outlined?: boolean;
  onClick?: MouseEventHandler<HTMLElement> ;
  fullWidth?: boolean;
  icon?: string;
  customClass?: string;
  type?: "button" | "submit" | "reset";
  link?: string;
  equalPadding?: boolean;
} & PropsWithChildren;

export default function Button(props: ButtonProps) {
  const {
    variant,
    children,
    onClick,
    fullWidth,
    icon,
    outlined,
    customClass,
    type,
    link,
    equalPadding
  } = props;

  const innerButtonContent = (
    <>
      {icon && icon.trim().length !== 0 ? <Icon name={icon} /> : null}
      {children}
    </>
  );

  const className = `btn btn-${variant} ${fullWidth ? "full-width" : ""} ${
    icon && icon.trim().length !== 0 ? "with-icon" : ""
  } ${outlined ? "outlined" : ""} ${customClass ? customClass : ""} ${equalPadding ? "equal-padding" :""}`;

  return (
    <>
      {link ? (
        <Link to={link} className={className} onClick={onClick}>
          {innerButtonContent}
        </Link>
      ) : (
        <button
          type={type ? type : "button"}
          className={className}
          onClick={onClick}
        >
          {innerButtonContent}
        </button>
      )}
    </>
  );
}
