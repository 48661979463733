import { useTranslation } from "react-i18next";
import appStoreBadge from "../../assets/images/badges/app-store-badge.png";
import googlePlayBadge from "../../assets/images/badges/google-play-badge.png";
import GKLogo from "../../assets/images/logos/small-logo.png";
import { Link } from "react-router-dom";
import useLocalizedLinks from "../../hooks/useLocalizedLinks";

type MenuSidebarAppPromoProps = {
  isMenuExpanded: boolean;
};

export default function MenuSidebarAppPromo(props: MenuSidebarAppPromoProps) {
  const { isMenuExpanded } = props;

  const {t} = useTranslation();

  const {getLocalizedLink} = useLocalizedLinks();

  return (
    <Link to={getLocalizedLink("aplikacja")} className="app-promo-container">
      <span className={`folded-text ${isMenuExpanded ? "hide" : ""}`}>
        {t("Pobierz aplikację")}
      </span>
      <img
        className={`gk-small-logo ${isMenuExpanded ? "enlarge" : ""}`}
        src={GKLogo}
        alt="gk-logo"
      />
      <div className={`stores-container ${isMenuExpanded ? "show" : ""}`}>
        <img src={appStoreBadge} alt="app-store" />
        <img src={googlePlayBadge} alt="google-play" />
      </div>
    </Link>
  );
}
