import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableList from "../../../shared/ScrollableList";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

const taxFreeServiceId = "10";

export default function Services() {
  const services = useAppSelector((state) => {
    return state.mapState.mapData?.service_category;
  })?.filter((service) => !!service.logo);

  const scrollableListRef = useRef<HTMLUListElement | null>(null);
  useDynamicBlocades(scrollableListRef);

  const {getLocalizedLink} = useLocalizedLinks();

  const { changePopupHeader } = useContext(ContentPopupContext);

  const {t} = useTranslation();

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Serwisy")
  },[logPageViewEvent])

  useLayoutEffect(() => {
    changePopupHeader(t("Serwisy"), "icm_serwisy");
  }, []);

  if (!services) return null;

  return (
    <div className="services-container">
      <ScrollableList ref={scrollableListRef} customClass="services-list">
        {services.map((service) => {
          return (
            <li key={service.id}>
              <NavLink to={service.id === taxFreeServiceId ? getLocalizedLink("tax-free") : service.service_url}>
                <div className="image-container">
                  <img
                    className="icon-image"
                    src={`${BACKEND_URL}/${service.logo}`}
                    alt={service.title}
                  />
                </div>
                <span>{service.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ScrollableList>
    </div>
  );
}
