import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { BACKEND_URL } from "../../../../utils/constants/backendContants";
import ShopParamsPanel from "../shared/ShopParamsPanel";
import Button from "../../../shared/Button";
import Divider from "../../../shared/Divider";
import Badge from "../../../shared/Badge";
import useHttp from "../../../../hooks/useHttp";
import NoPhoto from "../../../../assets/images/utility/no-photo.png";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ContentPopupContext } from "../../ContentPopup";
import ScrollableContainer from "../../../shared/ScrollableContainer";
import useDynamicBlocades from "../../../../hooks/useDynamicBlocades";
import { useTranslation } from "react-i18next";
import useLocalizedLinks from "../../../../hooks/useLocalizedLinks";
import useLogPageViewEvent from "../../../../hooks/useLogPageViewEvent";

type ShopDetailServerResponse = {
  id: string;
  image: string;
  date_add: string;
  title: string;
  description: string;
};

export default function ShopDetail() {
  const { shopId } = useParams();

  const navigate = useNavigate();

  const { mapData, mapLoaded, kioskLocation } = useAppSelector((state) => {
    return state.mapState;
  });

  const {t, i18n} = useTranslation();

  const [sendRequest, isLoading] = useHttp(
    `${BACKEND_URL}${i18n.language !== "pl" ? `/${i18n.language}` :""}/scheme/store-detail/id/${shopId}`,
    true
  );
  const [shopDescription, setShopDescription] = useState<string>("");

  const storeData = mapData?.store.find((store) => store.id === shopId);

  function handleStoreResponse(response: Response) {
    if (!response.ok) {
      throw new Error("Failed to fetch store data");
    }
    return response.json().then((data: ShopDetailServerResponse) => {
      setShopDescription(data.description);
    });
  }

  function handleStoreError(error: Error) {
    console.error(error.message);
  }

  const relatedStoresButtonsContainerRef = useRef<HTMLDivElement | null>(null);
  const promotionsListRef = useRef<HTMLDivElement | null>(null);
  const descriptionContainerRef = useRef<HTMLDivElement | null>(null);

  useDynamicBlocades(relatedStoresButtonsContainerRef);
  useDynamicBlocades(promotionsListRef);
  useDynamicBlocades(descriptionContainerRef);

  const logPageViewEvent = useLogPageViewEvent();

  useEffect(()=>{
    logPageViewEvent("Sklep",{storeName:storeData?.name})
  },[logPageViewEvent])

  const { changePopupHeader } = useContext(ContentPopupContext);

  const { getLocalizedLink } = useLocalizedLinks();

  useLayoutEffect(() => {
    if (storeData) {
      changePopupHeader(storeData.name, "", {
        storeId: storeData.id,
        imageUrl: `${BACKEND_URL}/${storeData.logo_list}`,
      });
    }
  }, [storeData]);

  useEffect(() => {
    if (shopId === undefined) return;
    sendRequest(handleStoreResponse, handleStoreError);
  }, [shopId]);

  useEffect(() => {
    if (storeData && mapLoaded) {
      map.showStoreWithCode(storeData.code, true);
    }
  }, [storeData, mapLoaded]);

  if (!mapData || !storeData) return null;

  const storeCategories = mapData.store_category
    .filter((category) => {
      return storeData.category_ids?.includes(category.id);
    })
    .map((category) => {
      return {
        imageUrl: `${BACKEND_URL}/${category.logo}`,
        title: category.title,
      };
    });

  return (
    <ScrollableContainer customClass="shop-detail-container">
      <ShopParamsPanel
        name={storeData.name}
        level={storeData.level}
        code={storeData.code}
        categories={storeCategories}
        // openHours={"10:00 - 20:00"}
        phone={storeData.phone}
        website={storeData.website}
      />
      <div className="navigation-buttons">
        
        {!!kioskLocation && <Button
          variant="primary"
          icon={"navigate"}
          fullWidth={true}
          onClick={() => {
            navigate("/?navigate_to_store=" + storeData.code);
          }}
        >
          {t("Nawiguj")}
        </Button>}

        {storeData.related_stores && storeData.related_stores.length > 0 ? (
          <>
            <span className="related-shops-info">
              {t("Wybrane miejsca dostępne są również")}:
            </span>
            <div className="level-buttons" ref={relatedStoresButtonsContainerRef}>
              {storeData.related_stores.map((store) => {
                return (
                  <Button
                    key={store.id}
                    variant="primary"
                    outlined={true}
                    fullWidth={true}
                    link={getLocalizedLink("sklepy",store.id)}
                  >
                    {t("poziom")} {store.level}
                  </Button>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
      <Divider />
      {storeData.promotions && storeData.promotions.length > 0 ? (
        <>
          <div className="shop-promotions-container">
            <Badge icon="icm_promocje">{t("Promocje")}</Badge>
            <div className="promotions-list" ref={promotionsListRef}>
              {storeData.promotions.map((promotion) => {
                return (
                  <Link
                    className="promotion"
                    to={getLocalizedLink("promocja",promotion.id)}
                    key={promotion.id}
                  >
                    <img
                      src={`${BACKEND_URL}/${promotion.image}`}
                      alt="promotion"
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
      <div
        className="description-container"
        ref={descriptionContainerRef}
        dangerouslySetInnerHTML={{
          __html: isLoading
            ? '<div class="loader-container"><div class="loader"></div></div>'
            : shopDescription,
        }}
      ></div>
    </ScrollableContainer>
  );
}
