import PL from "../../assets/images/utility/pl-flag.svg";
import EN from "../../assets/images/utility/en-flag.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const languages = [
  { name: "pl", flag: PL, value: "" },
  { name: "en", flag: EN, value: "en" },
];

type LanguageSwitchProps = {
  cssClass?: string;
  onLanguageImageClick?: (lang: {name: string, flag: string, value: string}) => void;
};

export default function LanguageSwitch(props: LanguageSwitchProps) {
  const { i18n } = useTranslation();

  const {cssClass, onLanguageImageClick} = props;

  const [languagesShown, setLanguagesShown] = useState(false);

  const activeLanguage = languages.find((lang) => {
    return lang.name === i18n.language;
  });

  const inactiveLanguages = languages.filter((lang) => {
    return lang.name !== i18n.language;
  });

  return (
    <div className={`language-switch ${cssClass ? cssClass : ""}`}>
      <div className="relative-container">
        <div
          className="active-language"
          onClick={() => {
            setLanguagesShown((prevValue) => {
              return !prevValue;
            });
          }}
        >
          <img
            className="language-flag"
            src={activeLanguage?.flag}
            alt={activeLanguage?.name}
          />
        </div>
        <div className={"inactive-laguages-container show"}>
          {inactiveLanguages.map((lang) => {
            return (
              <img
                key={lang.name}
                className="language-flag"
                src={lang.flag}
                width={50}
                alt={lang.name}
                onClick={() => {
                  if(onLanguageImageClick) {
                    onLanguageImageClick(lang);
                    return;
                  }
                  window.location.assign(`/${lang.value}`);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
